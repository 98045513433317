import { Component, OnInit, ViewChild } from '@angular/core';
import { QuizManagerService } from './quiz-manager.service'
import { ToastrManager } from 'ng6-toastr-notifications';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { TopicService } from '../topic/topic.service';
import { AddQuestionBankModalComponent } from './add-question-bank-modal/add-question-bank-modal.component';
// import { environment } from '../../../../environments/environment';
import { AppService } from "../../../app.service";

declare var $: any;
@Component({
  selector: 'app-quiz-manager',
  templateUrl: './quiz-manager.component.html',
  styleUrls: ['./quiz-manager.component.css']
})
export class QuizManagerComponent implements OnInit {
  questionList: any;
  quizList: any;
  totalRecords: any;
  toDeleteQuestionId: any;
  toDeleteQuizId:any;
  pageNumber: any = 1;
  pageSize: any = 30;
  tempPageObject: any = {};
  searchQuestionObject: any = {};
  customInput: Subject<string> = new Subject();
  customInputQuiz: Subject<string> = new Subject();
  pageSizeList: string[] = ["30", "50", "70"];
  selectedPageSize = "30";
  config: any;
  userQuestionObject: any = {};
  QuestionObject:any ={};
  topicList:any;
  filterCategoryId: any ='';
  selectedQuizType:any;
  getRefresherQuiz = '';
  @ViewChild(AddQuestionBankModalComponent, {static: false})
  addQuestionBankModalComponent: AddQuestionBankModalComponent;
  constructor(
    private service: QuizManagerService, 
    private toastr: ToastrManager,
    private topicService : TopicService,
    private appService: AppService) {
    this.customInput.pipe(debounceTime(1000), distinctUntilChanged()).subscribe(value => {
      console.log('API called all questions ', value);
      this.searchQuestionObject.Name = value;
      this.loadQuestionBanks(this.searchQuestionObject);
    });
    this.customInputQuiz.pipe(debounceTime(1000), distinctUntilChanged()).subscribe(value => {
      console.log('API called all questions ', value);
      this.searchQuestionObject.Name = value;
      this.loadQuiz(this.searchQuestionObject);
    });
  }

  ngOnInit(): void {
    this.config = {
      itemsPerPage: this.selectedPageSize,
      currentPage: this.pageNumber,
      totalItems: 0
    };
    this.getAllModules();
    this.loadQuiz(null);
  }
  tabSelected(data){
    if(data === "Quiz"){
      this.loadQuiz(null);
    }else if(data==="QuestionBank"){
      this.loadQuestionBanks(this.searchQuestionObject);
    }else if(data==="RefresherQuiz"){

    }
    this.filterCategoryId = '';
   // this.loadUserQuestions(this.searchUserQuestionObject);
  }
  setSelectedQuizType(text:string){
    
    this.selectedQuizType = text;
    this.getRefresherQuiz = '';
    if(this.selectedQuizType === 'RefresherQuiz'){
      this.selectedQuizType = '';
      this.getRefresherQuiz = 'True';
    }
    this.loadQuiz(null);
  }

  
  loadQuiz(event) {
    this.loader('show');
    let data = {};
    data = {
      'pageSize': this.pageSize, 'pageIndex': this.pageNumber,'quiz_type':this.selectedQuizType,
      'referesher_quiz': this.getRefresherQuiz, 'category_id': this.filterCategoryId,
      'search_quiz': this.searchQuestionObject ? (this.searchQuestionObject.Name ? this.searchQuestionObject.Name : '') : ''
    };
    this.service.getAllQuiz(data).subscribe((res: any) => {
      this.quizList = res.result;
      this.quizList.forEach(element => {
       if(element.image){
        //element.image = environment.BASE_URL.replace('/api/','') + element.image;
       }
      });
      this.totalRecords = res.count;
      this.config = {
        itemsPerPage: this.selectedPageSize,
        currentPage: this.pageNumber,
        totalItems: this.totalRecords
      };
      //this.setFilter();
      this.loader('hide');
    }, err => {
      this.loader('hide');
    });
  }

  loadQuestionBanks(event) {
    this.loader('show');
    let data = {};
    data = {
      'pageSize': this.pageSize, 'pageIndex': this.pageNumber,  'category_id': this.filterCategoryId, 'difficulty_type': '',
      'searchArticle': this.searchQuestionObject ? (this.searchQuestionObject.Name ? this.searchQuestionObject.Name : '') : ''
    };
    this.service.getAllQuestion(data).subscribe((res: any) => {
      this.questionList = res.results;
      this.questionList.forEach(element => {
       element.isAnswer = false;
       if(element.answer){
        element.isAnswer = true;
       }
      });
      this.totalRecords = res.count;
      this.config = {
        itemsPerPage: this.selectedPageSize,
        currentPage: this.pageNumber,
        totalItems: this.totalRecords
      };
      //this.setFilter();
      this.loader('hide');
    }, err => {
      this.loader('hide');
    });
  }
  getAllModules() {
    let data = {};
    data = { 'pageSize': 1000, 'pageIndex': 1, 'categoryType': '', 'searchName': '' };
    this.topicService.getAllTopic(data).subscribe((res: any) => {
      this.topicList = res.results;  
      this.topicList.forEach(element => {
        if(element.is_published === true){
          element.status = 'Published';
        } else{
          element.status = 'Draft';
        }
      });         
    }, err => {
      
    });
  }
  searchQuestion(event) {
    this.customInput.next(event);
  }
  searchQuiz(event) {
    this.customInputQuiz.next(event);
  }
  pageChanged(event) {
    this.pageNumber = event;
    this.loadQuestionBanks(this.searchQuestionObject);
    this.config.currentPage = event;
  }
  onPageSizeChange(event) {
    this.pageSize = event;
    this.pageNumber = 1;
    this.loadQuestionBanks(this.searchQuestionObject);
  }
  setSelected(data){
    if(data === 'All'){
      this.filterCategoryId = ''
    } else{
      this.filterCategoryId =data.pk;
    }
   this.loadQuestionBanks(this.searchQuestionObject);
  }
  confirmDelete(deleteQuestion) {
    this.toDeleteQuizId = 0;
    this.toDeleteQuestionId = deleteQuestion.pk;
  }
  confirmQuizDelete(deleteQuiz){
    
    this.toDeleteQuestionId  = 0;
    this.toDeleteQuizId = deleteQuiz.quiz_id;
  }

  deleteUserQuestion() {
    if(this.toDeleteQuestionId > 0){
      this.service.deleteQuestionBank(this.toDeleteQuestionId).subscribe(
        (res: any) => {
          this.toastr.successToastr('Deleted successfully.');
          $('#deleteModal').modal('hide');
          this.loadQuestionBanks(this.tempPageObject);
        },
        (error) => {
          this.toastr.errorToastr('This question is associated with respective module/quiz.');
        }
      );
    } else if(this.toDeleteQuizId > 0){
      this.service.deleteQuiz(this.toDeleteQuizId).subscribe(
        (res: any) => {
          this.toastr.successToastr('Deleted successfully.');
          $('#deleteModal').modal('hide');
          this.loadQuiz(this.tempPageObject);
        },
        (error) => {
          this.toastr.errorToastr('Deletion failed.');
        }
      );
    }

  }
  addQuestion(data?: any) {
    this.addQuestionBankModalComponent.addQuestionBank(false);
  }
  editQuestion(data: any) {
    this.addQuestionBankModalComponent.editQuestionBank(data);
  }
  viewQuestion(data: any) {    
    this.addQuestionBankModalComponent.viewQuestionBank(data);
  }

  loader(status: string){
    this.appService.setloaderVisibility(status);
  }
}
