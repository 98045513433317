import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { ToastrManager } from 'ng6-toastr-notifications';
import { AppService } from '../../app.service';
import { LoginService } from '../login/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  currentUser: any = {};
  rememberMe: any;
  loginPasswordfieldTextType:boolean;
  constructor(
    private router: Router,
    private service: LoginService,
    private toastr: ToastrManager,
    private appService: AppService
  ) { }

  ngOnInit(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('app');
    this.currentUser.username = localStorage.getItem('username');
    this.currentUser.password = localStorage.getItem('password');
    if (JSON.parse(localStorage.getItem('RememberMe')) !== null) {
      this.rememberMe = JSON.parse(localStorage.getItem('RememberMe'));
    }
  }

  login() {
    this.loader('show');
    Cookie.deleteAll();
    this.appService.currentUser.username= this.currentUser.username;
    this.appService.currentUser.password= this.currentUser.password
    if (this.rememberMe) {
      localStorage.setItem('username', this.currentUser.username);
      localStorage.setItem('password', this.currentUser.password);
      localStorage.setItem('RememberMe', JSON.stringify(this.rememberMe));
    } else {
      localStorage.removeItem('username');
      localStorage.removeItem('password');
      localStorage.removeItem('RememberMe');
    }
    this.currentUser.social_login = false;
    this.service.login(this.currentUser).subscribe((res: any) => {        
        if (res.token) {
          
          Cookie.set('token', res.token);
          Cookie.set('isLoggedIn', 'true');
          Cookie.set('userId', res.id);
          Cookie.set('email', this.currentUser.username);
          localStorage.setItem('userId', res.id);
         // Cookie.set('currentUser', JSON.stringify(res));
          this.router.navigate(['dashboard']);
        }
        this.loader('hide');
      },
      () => {
        this.loader('hide');
        this.toastr.infoToastr('Invalid email or password');
      }
    );
  }

  loader(status: string){
    this.appService.setloaderVisibility(status);
  }

  setRememberMe(event: any) {
    if (event === 0) {
      this.rememberMe = false;
      localStorage.removeItem('RememberMe');
    }
  }
  loginPasswordToggle(){
    this.loginPasswordfieldTextType = !this.loginPasswordfieldTextType;
  }
  goToForgotPasswordPage(){
    this.router.navigate(['/forgot-password']);
  }
}
