import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { UserService } from '../user.service';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

declare var jsPDF: any;
declare var $: any;
@Component({
  selector: 'app-add-user-modal',
  templateUrl: './add-user-modal.component.html',
  styleUrls: ['./add-user-modal.component.css']
})
export class AddUserModalComponent implements OnInit {
  modal_title: any;
  display = false;
  fileRemove: any;
  userObject: any = {};
  schoolObject: any = {};
  schoolList: any;
  private userRoles: string[] = ["Admin", "User"];
  standardList: string[] = ["6th", "7th", "8th", "9th", "10th"];
  genderList: string[] = ["Male", "Female"];
  selectedRole = "User";
  selectedStd = "";
  selectedGender = "";
  selectedSchool: any = 0;
  file: File;
  arrayBuffer: any;
  filelist: any;
  title = 'Xlxread';
  importfileName = '';
  isfileUpload: boolean = false;
  importStudList: any;
  @Input() currentUser: any = {};
  @Output() callGetAllUserDataEvent = new EventEmitter<any>();
  constructor(
    private router: Router, 
    private service: UserService, 
    private toastr: ToastrManager,
    ) { }

  ngOnInit(): void {
    this.getAllSchool();
  }
  addUser() {
    this.userObject = {};
    this.selectedStd = "";
    this.selectedGender = "";
    // this.userObject.email ="";
    this.userObject.is_staff = false;
    $("#student").prop("checked", true);
    this.modal_title = 'Add New User';
  }
  editUser(data) {
    this.userObject = data;
    this.selectedSchool = 0;
    if (this.userObject.is_staff === true) {
      this.selectedRole = 'Admin';
      $("#adminRole").prop("checked", true);
    } else if (this.userObject.is_staff === false) {
      this.selectedRole = 'User';
      $("#studentRole").prop("checked", true);
    }
    this.selectedStd = data.standard;
    this.selectedGender = data.gender;
    if (data.school_id) {
      this.selectedSchool = data.school_id;
    }
    this.roleChange(data.role);
    this.modal_title = 'Edit User';

  }
  roleChange(text: string) {
    this.selectedRole = text;
    if (this.selectedRole === 'Admin') {
      this.userObject.is_staff = true;
      this.userObject.role = 'admin';
    } else if (this.selectedRole === 'User') {
      this.userObject.is_staff = false;
      this.userObject.role = 'user';
    }
  }
  randomString(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  getAllSchool() {
    this.service.getAllSchool().subscribe((res: any) => {
      this.schoolList = res.result;
    }, err => {

    });
  }

  saveFile(data: any){
    let fileData = { 'file': this.file[0], 'data': data, 'fileRemove': this.fileRemove }
    if (fileData.file) {
      this.service.importfile(fileData).subscribe(res => {
       
      });
    } else {
      
    }
  }
  

  onSchoolChange(value) {
    if (value === 'AddSchool') {
      $('#addSchoolModal').modal('show');
    }
  }
  saveSchool() {
    try {

      if (this.schoolObject.pk) {
        this.service.updateSchool(this.userObject).subscribe(data => {
          $('#addSchoolModal').modal('hide');
          this.toastr.successToastr('User updated successfully');
          this.callGetAllUserDataEvent.emit('');
        }, err => {

        });
      } else {
        if (!this.schoolObject.name) {
          return this.toastr.infoToastr('School Name required');
        }
        this.service.addSchool(this.schoolObject).subscribe(data => {
          $('#addSchoolModal').modal('hide');
          this.schoolObject = {};
          this.toastr.successToastr('School added successfully');
          this.getAllSchool();
        }, err => {
        });
      }
    } catch (ex) {
      this.toastr.errorToastr('Please enter valid data');
    }
  }
  saveUser() {
    try {
      if(!this.selectedSchool){
        return this.toastr.errorToastr('school is mandatory ');
      }
      if (this.selectedSchool > 0) {
        this.userObject.school_id = this.selectedSchool;
      }
      this.userObject.gender = this.selectedGender;
      this.userObject.standard = this.selectedStd;
      if (this.userObject.pk) {
        this.userObject.is_update_profile = 'False';
        this.service.updateUser(this.userObject).subscribe(data => {
          $('#editUserModal').modal('hide');
          this.toastr.successToastr('User updated successfully');
          this.callGetAllUserDataEvent.emit('');
        });
      } else {
        if (this.userObject.email) {
          this.userObject.username = this.userObject.email;
        } else {
          if (this.userObject.is_staff == true) {
            return this.toastr.infoToastr('Email field required');
          }
          this.userObject.username = this.randomString(6);
        }
        this.service.addUser(this.userObject).subscribe(data => {
          $('#addUserModal').modal('hide');
          this.toastr.successToastr('User added successfully');
          this.callGetAllUserDataEvent.emit('');
        });
      }
    } catch (ex) {
      this.toastr.errorToastr('Please enter valid data');
    }
  }

  addfile(event) {
    this.file = event.target.files[0];
    if (this.file) {
      this.isfileUpload = true;
      this.importfileName = this.file.name;
    }
    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(this.file);
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      //console.log(XLSX.utils.sheet_to_json(worksheet, { raw: true }));
      this.importStudList = XLSX.utils.sheet_to_json(worksheet, { raw: true });

      // this.saveStudentList(arraylist);
    }
  }

  saveStudentList(studList?: any) {
    if (this.selectedSchool > 0) {
      this.importStudList.forEach(ele => {
        const studObject = {
          'is_staff': 'false', 'username': this.randomString(6),
          'first_name': ele.FirstName, 'last_name': ele.LastName, 'standard': ele.Standard,
          'gender': ele.Gender, 'school_id': this.selectedSchool
        };
        this.service.addUser(studObject).subscribe(data => {
          this.callGetAllUserDataEvent.emit('');
        }, err => {
        });
          this.service.importfile(studObject).subscribe((res: any) => {
            this.saveFile(res);
          this.callGetAllUserDataEvent.emit('');
        }, err => {
        });
      });
      $('#importUserModal').modal('hide');
      this.toastr.successToastr('students imported successfully');
    }
    else {
      this.toastr.infoToastr('Please select school name');
    }
  }

  exportStudentExcel() {
    if (this.selectedSchool > 0 ) {
      let data = {};
      data = { 'pageSize': 1000, 'pageIndex': 1, 'is_staff': 'False', 'searchUser': '','school_id': this.selectedSchool };
      this.service.getAllUser(data).subscribe((res: any) => {
        var alluserList = res.results;
        var studerntList = alluserList.filter(i => i.school_id == this.selectedSchool);
        var schoolStudentList = [];
        studerntList.forEach(element => {
          element.productKey = '';
          if (element.user.length > 0) {
            var userKey = element.user[0];
            element.productKey = userKey.key;
          }
          var tempStudentObj = { 'FirstName': '', 'LastName': '', 'Standard': '', 'Gender': '',
          'SchoolName': '', 'ProductKey': '' };
          tempStudentObj.FirstName = element.first_name;
          tempStudentObj.LastName = element.last_name;
          tempStudentObj.Standard = element.standard;
          tempStudentObj.Gender = element.gender;
          tempStudentObj.SchoolName = element.school;
          tempStudentObj.ProductKey = element.productKey;
          schoolStudentList.push(tempStudentObj);
        });
        this.exportAsExcelFile(schoolStudentList, 'student');
      });
    } else {
      this.toastr.infoToastr('Please select school name');
    }
  }
  exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  exportStudentPdf() {
    if (this.selectedSchool > 0) {
      let data = {};
      data = { 'pageSize': 1000, 'pageIndex': 1, 'is_staff': 'False', 'searchUser': '','school_id': this.selectedSchool };
      this.service.getAllUser(data).subscribe((res: any) => {
        var alluserList = res.results;
        var studerntList = alluserList.filter(i => i.school_id == this.selectedSchool);
        var schoolStudentList = [];
        studerntList.forEach(element => {
          element.productKey = '';
          if (element.user.length > 0) {
            var userKey = element.user[0];
            element.productKey = userKey.key;
          }
          var tempStudentObj = { 'FirstName': '', 'LastName': '', 'Standard': '', 'Gender': '',
          'SchoolName': '', 'ProductKey': '' };
          tempStudentObj.FirstName = element.first_name;
          tempStudentObj.LastName = element.last_name;
          tempStudentObj.Standard = element.standard;
          tempStudentObj.Gender = element.gender;
          tempStudentObj.SchoolName = element.school;
          tempStudentObj.ProductKey = element.productKey;
          schoolStudentList.push(tempStudentObj);
        });
        this.exportAsPdfFile(schoolStudentList, 'student');
      });
    } else {
      this.toastr.infoToastr('Please select school name');
    }
  }

  exportAsPdfFile(studList, fileName) {
    const doc = new jsPDF()
    let columns = ["FirstName", "LastName", "Standard", "Gender", "SchoolName", "ProductKey"];
    let rows :any =[];
    studList.forEach(e => {
      rows.push([e.FirstName, e.LastName, e.Standard, e.Gender, e.SchoolName, e.ProductKey]);
    });
    doc.autoTable(columns, rows);
    doc.save(fileName + '_export_' + new Date().getTime() + '.pdf');
  }
}
