import { Component, OnInit } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ErrorService } from "./error.service";
import { FormGroup, FormControl } from '@angular/forms';
import { AppService } from "../../../app.service";

declare var $: any;

@Component({
  selector: 'app-logerror',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {
  logerrorList: any;
  totalRecords: any;
  pageNumber: any = 1;
  pageSize: any = 30;
  tempPageObject: any = {}
  toDeleteArticleId: any;

  formdata;
  searchLogErrorObject: any = {}; 
  pageSizeList: string[] = ["30", "50", "70"];
  selectedPageSize = "30";
  config: any;
  logerrorId = 1;
  topicList: any = [];
  newLogErrorObj: any = {};
  Status: any = '';
  selectedLogError: any = {};
  customInput: Subject<string> = new Subject();
  totalEntries = 0;
  fromEntries = 1;
  toEntries = 1;
  logerrors = [];
  logerrorObject: any = {};

  constructor(
    private service: ErrorService,
    private toastr: ToastrManager,
    private appService: AppService
  ) {
    this.customInput.pipe(debounceTime(1000), distinctUntilChanged()).subscribe(value => {
      this.searchLogErrorObject.Name = value;
      // this.loadLogErrors();
    });
  }

  ngOnInit(): void {
    this.loadLogErrors();
    this.getAllLogError();
  }

  defineForm() {
    this.formdata = new FormGroup({
      header: new FormControl(""),
      about: new FormControl(""),
      phone: new FormControl(""),
      email: new FormControl(""),
      website1: new FormControl()
    });
  }

  getAllLogError(search?) {
    this.loader('show');
    const DATA = { search: search ? search : '' };
    this.service.getAllLogError(DATA).subscribe((res: any) => {
      this.loader('hide');
      this.logerrors = res.results;
      // this.logerrors.reverse();
      this.totalEntries = res.count;
    });
  }

  createLogError() {
    this.service.addLogErrors(this.newLogErrorObj).subscribe((res: any) => {
      // this.clearAddForm();
      this.getAllLogError();
      
      this.toastr.successToastr(res.details);
    });
    $('#addLogErrors').modal('hide');
  }

  // clearAddForm() {
  //   let name = document.getElementById('addName');
  //   let desc = document.getElementById('addDescription');
  //   let phone = document.getElementById('addLogErrorPhone');
  //   let email = document.getElementById('addLogErrorEmail');
  //   let url = document.getElementById('addLogErrorBrowserUrl');

  //   name.innerText = '';
  //   desc.innerText = '';
  //   phone.innerText = '';
  //   email.innerText = '';
  //   url.innerText = '';
  // }

  loadLogErrors() {
    this.loader('show');
    let data = {};
    data = {
      'pageSize': this.pageSize, 'pageIndex': this.pageNumber,
      'searchLogError': this.searchLogErrorObject ? (this.searchLogErrorObject.logerror_name ? this.searchLogErrorObject.logerror_name : '') : ''
    };
    this.service.getAllLogError(data).subscribe((res: any) => {
      this.loader('hide');
      // this.logerrorList = res.results;
      this.logerrors = res.results.reverse();

    });
  }

  setSelectedLogError(data) {
    this.selectedLogError = data;
  }

  deleteLogError() {
    this.service.deleteLogError(this.selectedLogError.pk).subscribe((res: any) => {
      this.getAllLogError();
      this.toastr.successToastr(res.status);
    });
    $('#deleteLogErrors').modal('hide');
  }

  // SaveChanges(name, description, phone_number, email, web_site, webte2) {
  //   let data = { 'logerror_id': this.selectedLogError.pk, 'header': name, 'about': description, 'phone': phone_number, 'email': email, 'website1': web_site, 'website2': webte2 };
  //   let data3 = {
  //     "logerror_id": this.selectedLogError.pk,
  //     "header": name,
  //     "about": description,
  //     "phone": phone_number,
  //     "email": email,
  //     "website1": web_site,
  //     "website2": webte2,
  //   }
  //   this.service.updateLogError(data3).subscribe((res: any) => {
  //     this.loadLogErrors();
  //     this.getAllLogError();

  //   });
  //   $('#editLogErrors').modal('hide');
  // }

  searchLogError(searchData: string) {
    this.getAllLogError(searchData);
  }

  loader(status: string) {
    this.appService.setloaderVisibility(status);
  }
}
