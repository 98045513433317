import { Component, OnInit } from '@angular/core';
import { ContentService } from './content.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AppService } from "../../../app.service";

declare var $: any;

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.css']
})
export class ContentComponent implements OnInit {
  totalEntries = 0;
  searchContentObject: any = {};
  customInput: Subject<string> = new Subject();
  fromEntries = 1;
  toEntries = 1;
  contents: any = {};
  contentList: any;
  users = [];
  filteredUsers = [];
  userList = [];
  oldUserList = [];
  userListName = [];
  selectedGroup: any = {};
  schools = [];
  formdata;
  selectAllUsers: boolean;
  checkAll: boolean;
  newContentObj: any = {};

  constructor(
    private contentService: ContentService, 
    private toastr: ToastrManager,
    private appService: AppService
    ) {
    this.customInput.pipe(debounceTime(1000), distinctUntilChanged()).subscribe(value => {
      this.searchContentObject.Name = value;
      this.loadContents();
    });
  }

  ngOnInit(): void {
    this.getAllContents();
    this.loadContents();
  }

  getAllContents(search?: any) {
    this.loader('show');
    const DATA = { search: search ? search : '' };
    this.contentService.getAllContents(DATA).subscribe((res: any) => {
      this.loader('hide');
      this.contents = res.data;
      this.totalEntries = res.count;
    });
  }

  deleteContent() {
    this.contentService.deleteContent(this.contents.pk).subscribe((res: any) => {
      this.getAllContents();
      this.toastr.successToastr(res.status);
    });
    $('#deleteContents').modal('hide');
  }

  loadContents() {
    this.loader('show');
    let data = {};
    data = {
      'searchContent': this.searchContentObject ? (this.searchContentObject.content_name ? this.searchContentObject.content_name : '') : ''
    };
    this.contentService.getAllContents(data).subscribe((res: any) => {
      this.loader('hide');
      this.contentList = res.results;
    });
  }

  trimString(text: any, length: any) {
    return text?.length > length ?
      text.substring(0, length) + '...' :
      text;
  }

  createContent() {
    this.contentService.addContent(this.newContentObj).subscribe((res: any) => {
      this.getAllContents();
      this.toastr.successToastr('Content added successfully');
    });
    $('#addContents').modal('hide');
  }

  SaveChanges(about1, privacy1, term1) {
    let data3 = {
      "content_id": this.contents?.pk,
      "about": about1,
      "privacy": privacy1,
      "term": term1
    }
    this.contentService.updateContent(data3).subscribe((res: any) => {
      this.toastr.successToastr('Content updated successfully');
      this.loadContents();
      this.getAllContents();
    });
    $('#editContents').modal('hide');
  }

  loader(status: string) {
    this.appService.setloaderVisibility(status);
  }
}
