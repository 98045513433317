import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ProfileService } from '../profile.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { AppService } from '../../../../app.service';
declare var $: any;
@Component({
  selector: 'app-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.css']
})
export class ChangePasswordModalComponent implements OnInit {
  newPassword: any;
  confirmPassword: any;
  oldPassword:any;
  newPasswordfieldTextType: boolean;
  oldPasswordfieldTextType:boolean;
  confirmPasswordfieldTextType:boolean;
  currentUserPassword: string;
  @BlockUI() blockUI: NgBlockUI;
  constructor(private appService:AppService, private service: ProfileService,private router: Router,private toastr: ToastrManager) { }
  email:any;
  ngOnInit(): void {
    this.email =Cookie.get('email');
  }
  
  changePassword() {
    if(this.appService.currentUser.password != undefined){
    this.currentUserPassword = this.appService.currentUser.password;
    } else {
      this.currentUserPassword = localStorage.getItem('password');
    }
    if(this.newPassword === undefined && this.confirmPassword === undefined && this.oldPassword === undefined){
      this.toastr.infoToastr('Please enter passwords.')
      return;
    } if (this.oldPassword === null || this.oldPassword === undefined) {
      this.toastr.infoToastr('Please enter old password.');
      return;
    }else if (this.newPassword === null || this.newPassword === undefined) {
      this.toastr.infoToastr('Please enter new password.');
      return;
    } else if (this.confirmPassword === null || this.confirmPassword === undefined) {
      this.toastr.infoToastr('Please enter confirm password.');
      return;
    } 
    // else if (this.oldPassword !== this.currentUserPassword) {
    //   this.toastr.infoToastr(' Old Password does not match.');
    //   return;
    // } 
    
    else if (this.newPassword !== this.confirmPassword) {
      this.toastr.infoToastr('New password and Confirm Password does not match.');
      return;
    }
    this.blockedUI(true);
    const data = { 'username': this.email, 'password': this.newPassword,'oldpassword':this.oldPassword };
    this.service.changePassword(data).subscribe((res: any) => {
      this.blockedUI(false);
      if (res.success) {
        this.toastr.successToastr('Password reset successfully');  
        this.newPassword = undefined;
        this.confirmPassword = undefined;
        $('#changePasswordModal').modal('hide');    
        this.router.navigate(['login']);
      } else {
        this.toastr.errorToastr('error occured');
      }
    }, error => {
      this.toastr.errorToastr('error occured');
      this.blockedUI(false);
    });
  }

  Close(){
    this.oldPassword = undefined;
    this.newPassword = undefined;
    this.confirmPassword = undefined;
    this.newPasswordfieldTextType = false;
    this.oldPasswordfieldTextType = false;
    this.confirmPasswordfieldTextType  = false;
  }

  newPasswordToggle() { 
    this.newPasswordfieldTextType = !this.newPasswordfieldTextType;
  }

  oldPasswordToggle() {
    this.oldPasswordfieldTextType = !this.oldPasswordfieldTextType;
  }

  confirmPasswordToggle() {
    this.confirmPasswordfieldTextType = !this.confirmPasswordfieldTextType;
  }

  logout() {
     Cookie.deleteAll();
     this.router.navigate(['login']);
  }

  blockedUI(value) {
    if (value) {
      this.blockUI.start('');
    } else {
      this.blockUI.stop();
    }
  }

}
