import { Component, OnInit } from '@angular/core';
import { AppService } from "../../../../app.service";
declare var $: any;

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {

  constructor(private appService: AppService) { }

  ngOnInit(): void {
    this.appService.showLoader.subscribe(res => {
      let status = res == 'show' ? res : 'hide';
      $('#loadingModel').modal(`${status}`);
    })
  }

}
