import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { UserQuestionsService } from './user-questions.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { AddUserQuestionsModalComponent } from './add-user-questions-modal/add-user-questions-modal.component';
import { ConfirmDeleteModalComponent } from '../../shared/modal/confirm-delete-modal/confirm-delete-modal.component';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { TopicService } from '../topic/topic.service';
import { AddFaqModalComponent } from './add-faq-modal/add-faq-modal.component';
import { AppService } from "../../../app.service";
declare var $: any;

@Component({
  selector: 'app-user-questions',
  templateUrl: './user-questions.component.html',
  styleUrls: ['./user-questions.component.css']
})
export class UserQuestionsComponent implements OnInit {
  questionList: any;
  faqQuestionList: any;
  totalRecords: any;
  toDeleteQuestionId: any;
  pageNumber: any = 1;
  pageSize: any = 30;
  tempPageObject: any = {};
  searchUserQuestionObject: any = {};
  customInput: Subject<string> = new Subject();
  pageSizeList: string[] = ["30", "50", "70"];
  selectedPageSize = "30";
  config: any;
  userQuestionObject: any = {};
  topicList:any;
  selectedTopic: any = {};
  filterCategoryId: any ='';
  faq = 'False';
  filterOnAnswer:any= '';

  @ViewChild(AddUserQuestionsModalComponent, { static: false })
  addUserQuestionsModalComponent: AddUserQuestionsModalComponent;
  @ViewChild(AddFaqModalComponent, { static: false })
  addFaqModalComponent: AddFaqModalComponent;
  @ViewChild(ConfirmDeleteModalComponent, { static: false })
  confirmDeleteModalComponent: ConfirmDeleteModalComponent;
  constructor(
    private service: UserQuestionsService, 
    private toastr: ToastrManager,
    private topicService : TopicService,
    private appService: AppService) {
    this.customInput.pipe(debounceTime(1000), distinctUntilChanged()).subscribe(value => {
     // console.log('API called all questions ', value);
      this.searchUserQuestionObject.Name = value;
      this.loadUserQuestions(this.searchUserQuestionObject);
    });
  }

  ngOnInit(): void {
    this.config = {
      itemsPerPage: this.selectedPageSize,
      currentPage: this.pageNumber,
      totalItems: 0
    };
    //this.getFilter();
    this.getAllModules();
    this.loadUserQuestions(this.searchUserQuestionObject);
  }
  getAllModules() {
    let data = {};
    data = { 'pageSize': 1000, 'pageIndex': 1, 'categoryType': '', 'searchName': '' };
    this.topicService.getAllTopic(data).subscribe((res: any) => {
      this.topicList = res.results;  
      this.topicList.forEach(element => {
        if(element.is_published === true){
          element.status = 'Published';
        } else{
          element.status = 'Draft';
        }
      });         
    }, err => {
      
    });
  }
  
  setSelected(data){
    if(data === 'All'){
      this.filterCategoryId = ''
      this.filterOnAnswer = ''
    }else if(data === "True") {
      this.filterOnAnswer = data
    }else if(data === 'False') {
      this.filterOnAnswer = data
    } else{
      this.filterCategoryId =data.pk;
    }
   this.loadUserQuestions(this.searchUserQuestionObject);
  }

  tabSelected(data){
    if(data === "FAQs"){
      this.faq= 'True';
    }else{
      this.faq= 'False';
    }
    this.filterCategoryId = '';
    this.loadUserQuestions(this.searchUserQuestionObject);
  }
  loadUserQuestions(event) {
    this.loader('show');
    let data = {};
    data = {
      'pageSize': this.pageSize, 'pageIndex': this.pageNumber,'answer': this.filterOnAnswer, 'category_id': this.filterCategoryId, 'faq': this.faq,
      'search': this.searchUserQuestionObject ? (this.searchUserQuestionObject.Name ? this.searchUserQuestionObject.Name : '') : ''
    };
    if(this.faq === "False"){
      this.service.getAllUserQuestion(data).subscribe((res: any) => {
        var length = 33;
        this.questionList = res.results;
        this.questionList.forEach(element => {
          if (element.answer != null){
          var trimmedString = element.answer.length > length ? element.answer.substring(0, length - 3) + "..." : element.answer;
          element.trimmedAnswer = trimmedString;
          }
        });
        this.questionList.forEach(element => {
        element.isAnswer = false;
        if(element.answer){
          element.isAnswer = true;
        }
        });
        this.totalRecords = res.count;
        this.config = {
          itemsPerPage: this.selectedPageSize,
          currentPage: this.pageNumber,
          totalItems: this.totalRecords
        };
        //this.setFilter();
        this.loader('hide');
      }, err => {
        this.loader('hide');
      });
  }else {
    this.service.getAllUserQuestion(data).subscribe((res: any) => {
      var length = 33;
      this.faqQuestionList = res.results;
      this.faqQuestionList.forEach(element => {
        if (element.answer != null){
        var trimmedString = element.answer.length > length ? element.answer.substring(0, length - 3) + "..." : element.answer;
        element.trimmedAnswer = trimmedString;
        }
       });
      this.faqQuestionList.forEach(element => {
       element.isAnswer = false;
       if(element.answer){
        element.isAnswer = true;
       }
      });
      this.totalRecords = res.count;
      this.config = {
        itemsPerPage: this.selectedPageSize,
        currentPage: this.pageNumber,
        totalItems: this.totalRecords
      };
      //this.setFilter();
      this.loader('hide');
    }, err => {
      this.loader('hide');
    });
  }
  }
  searchUser(event) {
    this.customInput.next(event);
  }
  pageChanged(event) {
    this.pageNumber = event;
    this.loadUserQuestions(this.searchUserQuestionObject);
    this.config.currentPage = event;
  }
  onPageSizeChange(event) {
    this.pageSize = event;
    this.pageNumber = 1;
    this.loadUserQuestions(this.searchUserQuestionObject);
  }
  addUserQuestion(data) {
    this.addUserQuestionsModalComponent.addUserQuestion(data);
  }
  editUserQuestion(data) {
    this.addUserQuestionsModalComponent.editUserQuestion(data);
  }
  viewUserQuestion(data) {    
    this.userQuestionObject = data;   
  }
  confirmDelete(deleteQuestion) {
    this.toDeleteQuestionId = deleteQuestion.pk;
  }
  deleteUserQuestion() {
    this.service.deleteUser(this.toDeleteQuestionId).subscribe(
      (res: any) => {
        this.toastr.successToastr('Deleted successfully.');
        $('#deleteModal').modal('hide');
        this.loadUserQuestions(this.tempPageObject);
      },
      (error) => {
        this.toastr.errorToastr('Deletion failed.');
      }
    );
  }

  addFaqs(){
    this.addFaqModalComponent.addFaqs();
  }
  viewFaqs(data){
    this.userQuestionObject = data;  
  }
  editFaqs(data){
    this.addFaqModalComponent.editFaqs(data);
  }
  loader(status: string){
    this.appService.setloaderVisibility(status);
  }
}
