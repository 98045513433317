import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
declare var $:any;

@Injectable({
  providedIn: 'root'
})
export class TopicService {

  constructor(private http: HttpClient) { }

  createTopic(data) {
    return this.http.post(environment.BASE_URL + 'topic/category', data).pipe(map(res => res));
  }
  getAllTopic(data) {
    return this.http.get(environment.BASE_URL + 'topic/category/list?page='+ data.pageIndex+ '&page_size='+ data.pageSize +'&search_type='+ data.searchName + '&category_type=' + data.categoryType).pipe(map(res => res));
  }
   updateTopic(data) {
    return this.http.put(environment.BASE_URL + 'topic/category', data).pipe(map(res => res));
  }
  deleteTopic(data) {
    return this.http.delete(environment.BASE_URL + 'topic/category?category_id='+ data.category_id).pipe(map(res => res));
  }

  addTopicFile(fileData){
    let file: File = fileData.file;
    let formData: FormData = new FormData();
    if(file){
      formData.append('file', file, file.name); 
    }
      formData.append('category_id', fileData.data.category_id);
      formData.append('url', fileData.data.url);
      formData.append('fileRemove', fileData.fileRemove);
      let headers = new HttpHeaders();
      headers.append('Content-Type', 'multipart/form-data');
      headers.append('Accept', 'application/json');
      let options = { headers: headers };
    return this.http.post(environment.BASE_URL + 'topic/category/icon', formData, options).pipe(map(res => res));

  }
}
