import { Component, OnInit } from '@angular/core';
import { AppService } from '../../../app.service';
import { DashboardService } from "./dashboard.service";
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  summary: any;
  curretUrl: string;

  constructor(
    private service: DashboardService,
    private appService: AppService
  ) { }

  ngOnInit(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('app');
    this.getSummary();
    this.appService.currentMessage.subscribe(url => {
      this.curretUrl = url
    })
  }

  loader(status: string) {
    this.appService.setloaderVisibility(status);
  }

  passActiveUrl(currentUrl: string) {
    this.appService.changeMessage(currentUrl)
  }

  getSummary() {
    this.loader('show');
    this.service.getSummary().subscribe((res: any) => {
      this.loader('hide');
      this.summary = res;
    }, err => {
      this.loader('hide');
    })
  }
}
