import { Component, OnInit, ViewChild } from '@angular/core';
import { AddArticleModalComponent } from '../article/add-article-modal/add-article-modal.component';
import { ArticleService } from './article.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { TopicService } from '../topic/topic.service';
import {Router} from '@angular/router';
import { AppService } from "../../../app.service";
declare var $: any;
@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.css']
})
export class ArticleComponent implements OnInit {
  articleList: any;
  totalRecords: any;
  toDeleteArticleId: any;
  pageNumber: any = 1;
  pageSize: any = 30;
  tempPageObject: any = {};
  searchArticleObject: any = {};
  customInput: Subject<string> = new Subject();
  pageSizeList: string[] = ["30", "50","70"];
  selectedPageSize = "30";
  config: any;
  articleId =1;
  topicList: any = [];
  publishedStatus: any = '';
  filterCategoryId: any ='';
  moduleList:any;
  moduleIdList:any =[];
  filterModuleIdList: any = [];
  filterModuleList: any = [];
  @ViewChild(AddArticleModalComponent, { static: false })
  addArticleModalComponent: AddArticleModalComponent;
  constructor(
    private service: ArticleService, 
    private topicService: TopicService,
    private router: Router, 
    private toastr: ToastrManager,
    private appService: AppService) { 
    this.customInput.pipe(debounceTime(1000), distinctUntilChanged()).subscribe(value => {
     // console.log('API called loadArticles ', value);
      this.searchArticleObject.Name = value;
      this.loadArticles(this.searchArticleObject);
    });
  }

  ngOnInit(): void {
    this.config = {
      itemsPerPage: this.selectedPageSize,
      currentPage: this.pageNumber,
      totalItems: 0
    };
    this.getFilter();
    this.loadArticles(this.searchArticleObject);
    this.getAllModules();

  }

  getAllModules() {
    let data = {};
    data = { 'pageSize': 1000, 'pageIndex': 1, 'categoryType': '', 'searchName': '' };
    this.topicService.getAllTopic(data).subscribe((res: any) => {
      this.moduleList = res.results;  
      this.moduleList.forEach(element => {
        if(element.is_published === true){
          element.status = 'Published';
        } else{
          element.status = 'Draft';
        }
        this.moduleIdList.push(parseInt(element.pk))
      });         
    }, err => {
      
    });
  }

  filterModules() {
     this.filterModuleList = this.moduleList.filter(o1 => this.filterModuleIdList.some(o2 => o1.pk === o2));
  }

  loadArticles(event) {
    this.loader('show');
    let data = {};
    data = { 'pageSize': this.pageSize, 'pageIndex': this.pageNumber, 'category_id': this.filterCategoryId, 'is_published' : this.publishedStatus,
    'searchArticle':  this.searchArticleObject ? ( this.searchArticleObject.Name ? this.searchArticleObject.Name : '' ): '' };

    this.service.getAllArticle(data).subscribe((res: any) => {
      this.loader('hide');
      this.articleList = res.results;
      this.articleList.forEach(element => {       
        if(element.is_published === true){
          element.status = 'Published';
        } else{
          element.status = 'Draft';
        }
        this.filterModuleIdList.push(parseInt(element.category_id))
      });
      this.filterModuleIdList = this.filterModuleIdList.filter(function(elem, index, self) {
        return index === self.indexOf(elem);
      }) 
      this.totalRecords = res.count;
      this.config = {
        itemsPerPage: this.selectedPageSize,
        currentPage: this.pageNumber,
        totalItems: this.totalRecords
      };
      this.setFilter();
    }, err => {
      this.loader('hide');
    });
  }
  getAllTopic() {
    
    return new Promise((resolve, reject) => {
    const data = { 'pageSize': 100, 'pageIndex': 1, 'searchName': '' };
    this.topicService.getAllTopic(data).subscribe((res: any) => {
      this.topicList = res.results;     
    }, err => {
    });
  });
  }
  searchArticle(event) {
    this.customInput.next(event);
  }
  setSelected(data){
    this.filterCategoryId = "";
    if (data === '') {
      this.publishedStatus = "";
    }else if (data === 'True'){
      this.publishedStatus = data;
    }else if (data === 'False') {
      this.publishedStatus = data;
    }else{
      this.filterCategoryId = data.pk.toString();
    }
    this.loadArticles(this.searchArticleObject);
  }

  setFilter(){
    this.searchArticleObject.pageNumber = this.pageNumber;
    this.searchArticleObject.pageSize = this.pageSize;
    this.searchArticleObject.publishedStatus = this.publishedStatus;
    this.searchArticleObject.module = 'article';
    localStorage.setItem('searchObject', JSON.stringify(this.searchArticleObject));
  }

  getFilter(){
    var searchObject = JSON.parse(localStorage.getItem('searchObject'));
    if( searchObject !== null){
      if(searchObject.module === 'article'){
        this.searchArticleObject = searchObject;
        this.pageNumber =this.searchArticleObject.pageNumber ;
        this.pageSize = this.searchArticleObject.pageSize ;
        this.selectedPageSize = this.searchArticleObject.pageSize ;
        this.publishedStatus= this.searchArticleObject.publishedStatus ;
      }
    }  
  }

  addArticle() {
    const data = {}
    this.router.navigate(['add-article'], { state: { data } });
  }
  editArticle(data) {
    this.router.navigate(['add-article'], { state: { data } });
  }
  confirmDelete(deleteArticle) {
    this.toDeleteArticleId = deleteArticle.pk;
  }
  deleteArticle() {
    const data = { 'article_id': this.toDeleteArticleId };
    this.service.deleteArticle(data).subscribe((res: any) => {
      if (res.success) {
        this.toastr.successToastr('Deleted successfully.');
        $('#deleteModalLearn').modal('hide');
        this.loadArticles(this.tempPageObject);
      }
    },
      (error) => {
        this.toastr.errorToastr('The Read article not be deleted, user already used this article.');
      }
    );
  }

  pageChanged(event){
    this.pageNumber = event;
    this.loadArticles(this.tempPageObject);  
  }
  onPageSizeChange(event){
    this.pageSize = event;
    this.pageNumber = 1;
    this.loadArticles(this.tempPageObject);
  }

  loader(status: string){
    this.appService.setloaderVisibility(status);
  }
}