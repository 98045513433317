import { Component, OnInit, ViewChild } from '@angular/core';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { ChangePasswordModalComponent } from '../../../../views/admin/profile/change-password-modal/change-password-modal.component';
import { NotificationService } from '../../../../views/notification/notification.service';
import { ProfileService } from "../../../../views/admin/profile/profile.service";
import { AppService } from '../../../../app.service';
@Component({
  selector: 'app-home-header',
  templateUrl: './home-header.component.html',
  styleUrls: ['./home-header.component.css']
})
export class HomeHeaderComponent implements OnInit {
  currentUser: any = {};
  notificationList: any;
  @ViewChild(ChangePasswordModalComponent, { static: false })
  changePasswordModalComponent: ChangePasswordModalComponent;

  constructor(
    private notificationService: NotificationService,
    private profileService: ProfileService,
    private appService: AppService
    ) { }

  ngOnInit(): void {
    this.getNotification();
    this.getUserProfile();
    this.appService.updatedcurrentUser.subscribe(message => this.currentUser = message)
  }

  hideActiveMenu(){
    const removeElements = (elms) => elms.forEach(el => el.remove());

removeElements( document.querySelectorAll(".side-menu-active") );
  }

  getUserProfile(){
    let currentUserId = Cookie.get('userId');
    this.profileService.getUser(currentUserId).subscribe((res: any) => {
      this.currentUser = res;
    });
  }

  changePassword() {
    this.changePasswordModalComponent.changePassword();
  }

  getNotification() {
    this.notificationService.getAllNotification().subscribe((res: any) => {
      this.notificationList = res.results;
      this.notificationList.forEach((element, index) => {
        if (index < 10) {
          element.created_at = this.convertUTCDateToLocalDate(new Date(element.created_at));
        }
      });
    });
  }

  convertUTCDateToLocalDate(date) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();
    newDate.setHours(hours - offset);
    return date;
  }

  logout() {
    Cookie.deleteAll();
    this.changePasswordModalComponent.logout();
    // this.router.navigate['login'];
  }
}
