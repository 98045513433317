export const changeDateFormat = (data: Date) => {
    const MONTHS = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    let current_datetime = new Date(data);
    let formatted_date = current_datetime.getDate() + "-" + MONTHS[current_datetime.getMonth()] + "-" + current_datetime.getFullYear()
    return formatted_date;
}

export const replaceAll = (string: string, search: any, replace: any) => {
    return string?.split(search).join(replace);
}

export const POINTS = {
    "ahc_plages": "AHC Pledges",
    "written_testimonial": "Positive Behavior - Written Testimonial",
    "anser_all_question_of_day_for_week": "Answer all question of the day in a week",
    "question_of_Day": "Question of the day",
    "submit_question": "Submit a Question",
    "quiz-hard": "Quiz - Hard",
    "quiz-medium": "Quiz - Medium",
    "quiz-easy": "Quiz - Easy",
    "watch": "Watch",
    "learn": "Learn",
    "read": "Read",
    "refresher-quiz": "Refresher/Challenge Quiz",
    "ahc_champion": "AHC Champion"
}

export const sortArrByDate = (arr: [], field: string) => {
    arr?.sort(function (a: any, b: any) {
        let date1: any = new Date(b[`${field}`]);
        let date2: any = new Date(a[`${field}`]);
        return date1 - date2
    });
    return arr;
}

export const secondsToTime = (secs: any) => {
    let hours: any = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes: any = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds: any = Math.ceil(divisor_for_seconds);

    hours = ("0" + hours).slice(-2);
    minutes = ("0" + minutes).slice(-2);
    seconds = ("0" + seconds).slice(-2);

    let obj = { h: hours, m: minutes, s: seconds }
    return obj;
} 
