import { Component, OnInit, EventEmitter,Input, Output } from '@angular/core';
import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill'
import ImageCompress from 'quill-image-compress';
import * as Quill from 'quill'
Quill.register('modules/imageCompress', ImageCompress);


@Component({
  selector: 'app-quill-text-editor',
  templateUrl: './quill-text-editor.component.html',
  styleUrls: ['./quill-text-editor.component.css']
})
export class QuillTextEditorComponent implements OnInit {
  modules = {}
  name = 'Angular 6';
  quillEditorRef;
  maxUploadFileSize = 1000000;
  content: string;
  @Output() callGetTextEditorDataEvent = new EventEmitter<any>();
  @Input() editorContent: string;
  constructor() { }

  ngOnInit(): void {
    
    this.quillEditorRef.clipboard.dangerouslyPasteHTML(this.editorContent);
    //this.content = this.editorContent;
    this.modules = {
      // 'emoji-shortname': true,
      // 'emoji-textarea': true,
      // 'emoji-toolbar': true,
      'toolbar': [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        // ['blockquote', 'code-block'],

        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        
        [{ 'direction': 'rtl' }],                         // text direction

        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'font': [] }],
        [{ 'align': [] }],

        ['clean'],                                         // remove formatting button

        ['link', 'image', 'video'],                         // link and image, video
        // ['emoji']

      ],
      'imageCompress': {
        "quality": 1, // default
        "maxWidth": 500, // default
        "imageType": 'image/jpeg', // default
        "debug": true, // default
    }
   }
  }
  editorContentText(data){
    
    this.quillEditorRef.clipboard.dangerouslyPasteHTML(data);
  }
  changedEditor(event: EditorChangeContent | EditorChangeSelection) {
    
   // console.log('editor-change', event)
    this.content = document.querySelector(".ql-editor").innerHTML;
    this.callGetTextEditorDataEvent.emit(this.content);
  }
  getEditorInstance(editorInstance: any) {
    
    this.quillEditorRef = editorInstance;
    //console.log(this.quillEditorRef)
    this.quillEditorRef.clipboard.dangerouslyPasteHTML(this.editorContent);
    const toolbar = editorInstance.getModule('toolbar');
    toolbar.addHandler('image', this.imageHandler);
  }
  
  imageHandler = (image, callback) => {
    const input = <HTMLInputElement> document.getElementById('fileInputField');
    document.getElementById('fileInputField').onchange = () => {
      let file: File;
      file = input.files[0];
      if (/^image\//.test(file.type)) {
        if (file.size > this.maxUploadFileSize) {
          alert('Image needs to be less than 1MB');
        } else {
          const reader  = new FileReader();
          reader.onload = () =>  {
            
            const range = this.quillEditorRef.getSelection();
            const img = '<img src="' + reader.result + '" width="70" height="70"/>';
            this.quillEditorRef.clipboard.dangerouslyPasteHTML(range.index, img);
          };
          reader.readAsDataURL(file);
        }
      } else {
          alert('You could only upload images.');
      }
    };

    input.click();
  }
}
