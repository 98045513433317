import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PointsManagementService {

  constructor(private http: HttpClient) { }

  getAllPoints() {
    return this.http.get(environment.BASE_URL + 'pointsetting').pipe(map(res => res));
  }

  updatePoint(data: any){
    return this.http.put(environment.BASE_URL + 'pointsetting', data).pipe(map(res => res));
  }
}
