import { Component, OnInit } from '@angular/core';
import { BadgesService } from "./badges.service";
import { ToastrManager } from 'ng6-toastr-notifications';
import { FormGroup, FormControl} from '@angular/forms';
import { AppService } from "../../../app.service";
declare var $: any;

@Component({
  selector: 'app-badges',
  templateUrl: './badges.component.html',
  // styleUrls: ['./badges.component.css']
})

export class BadgesComponent implements OnInit {
  modules = [];
  badgeLevel = '';
  badges = [];
  selectedBadge: any = {};
  imageUrl: any;
  fileName = '';
  file: any = {};
  fileRemove: any;
  formdata: any;
  module: any = {};
  badgeType: string = 'badge';
  constructor(
    private service: BadgesService, 
    private toastr: ToastrManager,
    private appService: AppService
    ) { }

  ngOnInit(): void {
    this.defineForm();
    this.getModules();
  }

  defineForm(){
    this.formdata = new FormGroup({
      name: new FormControl(""),
      description: new FormControl(""),
      module: new FormControl("")
   });
  }

  getModules(){
    this.service.getAllModules().subscribe((res: any) => {
      this.modules = res.results;
      this.getAllBadges();
    })
  }

  setBadgelevel(level){
    this.badgeLevel = level;
  }

  setBadgeType(level: any){
    this.badgeType = level;
  }

  getAllBadges(search?){
    this.loader('show');
    const DATA = {
      search: search ? search : ''
    }
    this.service.getAllBadges(DATA).subscribe((res: any) => {
      this.loader('hide');
      this.badges = res.results;
      this.badges.forEach(badge => {
        badge.category_name = this.getmoduleName(badge.category_id)
        badge.level = this.getLevelNm(badge.badge_type, badge.category_name)
      })
    }, err => {
      this.loader('hide');
    })
  }

  getmoduleName(moduleId: any){
    if(moduleId){
      let filteredModule = this.modules.filter((module:any) => module.pk === moduleId);
      return filteredModule[0].name;
    }
  }

  addBadge(badge: any){
    let modal = { id: 'addBadge', for: 'added' };
    let category_id = this.badgeType == 'superpower' ? null : badge.module;
    let badge_type = this.badgeType == 'superpower' ? null : this.badgeLevel;
    
    let newBadge: any = {
      name: badge.name,
      description: badge.description,
      badge_image: this.imageUrl,
      types: this.badgeType,
      max_point_earned: 0,
      category_id: category_id,
      badge_type: badge_type
    };
    this.service.addBadge(newBadge).subscribe((res: any) => {
      this.saveImage(res, modal);
    })
  }

  updateBadge(name: string, description: string, cat_id: any){
    let modal = { id: 'editBadge', for: 'updated' };
    let badge_type = this.badgeLevel ? this.badgeLevel : this.selectedBadge.badge_type;
    badge_type = this.badgeType == 'superpower' ? null : badge_type;
    let category_id = this.badgeType == 'superpower' ? null : cat_id;
    let types = this.badgeType ? this.badgeType : this.selectedBadge.types;
    let badge = {
      badge_id: this.selectedBadge.pk,
      name: name,
      description: description,
      category_id: category_id,
      badge_type: badge_type,
      types: types,
      badge_image: this.imageUrl,
      max_point_earned: 0
    }
    this.service.updateBadge(badge).subscribe((res: any) => {
      this.saveImage(res, modal);
    })
  }

  saveImage(data: any, modal: {id: string, for: string}){
    let fileData = { 'file': this.file[0], 'data': data, 'fileRemove': this.fileRemove }
    if (fileData.file) {
      this.service.addBadgeFile(fileData).subscribe(res => {
        this.activityAfterModalClose(modal);
      });
    } else {
      this.activityAfterModalClose(modal);
    }
    this.file = '';
  }

  deleteBadge(){
    this.service.deleteBadge(this.selectedBadge.pk).subscribe((res: any) => {
      this.activityAfterModalClose({ id: 'deleteBadge', for: 'deleted' });
    })
  }

  activityAfterModalClose(modal: {id: string, for: string}){
    this.getAllBadges();
    $(`#${modal.id}`).modal('hide');
    this.toastr.successToastr(`Badge ${modal.for} successfully`);
    if(modal.id === 'addBadge'){
      this.formdata.reset();
    }
  }

  setSelectedBadge(badge: any){
    this.selectedBadge = badge;
    this.imageUrl = badge.badge_image;
    this.badgeType = badge.types;
    this.selectedModule(badge.category_id);
  }

  getImage(selectedImg: any){
    return selectedImg ? selectedImg : this.selectedBadge.badge_image;
  }

  searchGroup(searchData: string){
    this.getAllBadges(searchData);
  }

  onFileChange(file: File) {
    if (file) {
      this.fileName = file.name;
      this.file[0] = file;

      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = event => {
        this.imageUrl = reader.result;
        this.fileRemove = false;
      };
    }
  }

  removeImage(){
    this.file = {};
    this.imageUrl = '../../../../assets/img/avtar.svg';
    this.fileRemove = true;
  }

  selectedModule(id: string){
    this.module = this.modules.find(i => i.pk == id);
  }

  getLevelNm(name: string, module?: string){
    let mod = module ? module : this.module?.name;
    switch (name) {
      case 'junior':
        return 'Champion-in-Training';
      case 'senior':
        return 'Junior Champion';
      case 'champion':
        return `${mod} Champion`;
      default:
        return ''
    }
  }

  loader(status: string) {
    this.appService.setloaderVisibility(status);
  }
}
