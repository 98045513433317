import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class TechnicalSupportService {
  constructor(private http: HttpClient) { }

  getIssues(data) {
    if(data.problem_type){
      return this.http.get(environment.BASE_URL + 'topic/issue?problem_type=' + data.problem_type + 
        '&page_size=' + data.pageSize + '&page=' + data.pageIndex + '&search=' + data.search).pipe(map(res => res));
    } else {
      return this.http.get(environment.BASE_URL + 'topic/issue?page_size=' + data.pageSize + '&page=' + data.pageIndex +
      '&search=' + data.search).pipe(map(res => res));
    }
  }
  updateIssueStatus(data) {
    return this.http.put(environment.BASE_URL + 'topic/issue?id=' + data.id +'&status=' + data.status, data).pipe(map(res => res));
  }

  getFeedback(data){
    // return this.http.get(environment.BASE_URL + 'topic/feedback/list').pipe(map(res => res));
    return this.http.get(environment.BASE_URL + 'topic/feedback/list?feedback='+data.search).pipe(map(res => res));
  }

  deleteFeedback(id: any) {
    return this.http.delete(environment.BASE_URL + 'topic/feedback?id=' + id).pipe(map(res => res));
  }

}
