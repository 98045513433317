import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
  })
  export class ContentService {
  
    constructor(private http: HttpClient) { }
  

  
    getAllContents(data) {
    //   return this.http.get(environment.BASE_URL + `topic/support?search=${data.search}`);
      return this.http.get(environment.BASE_URL + `topic/support`);
    }

    deleteContent(pk) {
      // return this.http.delete(environment.BASE_URL + 'topic/support?id='+ pk).pipe(map(res => res));
         return this.http.delete(environment.BASE_URL + 'topic/support?id=' + pk).pipe(map(res => res));
      }


    addContent(data) {
        return this.http.post(environment.BASE_URL + 'topic/support', data).pipe(map(res => res));
      }  

      updateContent(data) {
        return this.http.put(environment.BASE_URL + 'topic/support',data).pipe(map(res => res));
      }  
  
  
  }