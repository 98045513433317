import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
declare var $:any;

@Injectable({
  providedIn: 'root'
})
export class ArticleService {

  constructor(private http: HttpClient) { }
  createArticle(data) {    
    return this.http.post(environment.BASE_URL + 'topic/article', data).pipe(map(res => res));
  }
  getAllArticle(data) {
    return this.http.get(environment.BASE_URL + 'topic/article/list?page=' + data.pageIndex + '&page_size=' + data.pageSize + '&search_article=' + data.searchArticle + '&category_id=' + data.category_id + '&article_type=image&is_published=' + data.is_published).pipe(map(res => res));
  }
   updateArticle(data) {
    return this.http.put(environment.BASE_URL + 'topic/article', data).pipe(map(res => res));
  }
  deleteArticle(data) {
    return this.http.delete(environment.BASE_URL + 'topic/article?article_id='+ data.article_id).pipe(map(res => res));
  }
  getArticleByID(id){
    return this.http.get(environment.BASE_URL + 'topic/article?id='+ id +'').pipe(map(res => res));
  }

  addArticleFile(fileData){
    let file: File = fileData.file;
    let formData: FormData = new FormData();
    if(file){
      formData.append('file', file, file.name); 
    }
      formData.append('article_id', fileData.data.article_id);
      formData.append('url', fileData.data.url);
      formData.append('fileRemove', fileData.fileRemove);
      let headers = new HttpHeaders();
      headers.append('Content-Type', 'multipart/form-data');
      headers.append('Accept', 'application/json');
      let options = { headers: headers };
    return this.http.post(environment.BASE_URL + 'topic/article/video', formData, options).pipe(map(res => res));

  }
  
  getArticleFile(id){
    return this.http.get(environment.BASE_URL +'topic/article/video?id=' + id).pipe(map(res => res));
  }
  
}