import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { TopicService } from '../topic/topic.service';
import { VideoService } from './video.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { AppService } from "../../../app.service";
declare var $: any;
@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css']
})
export class VideoComponent implements OnInit {
  videoList: any;
  totalRecords: any;
  toDeleteVideoId: any;
  pageNumber: any = 1;
  pageSize: any = 30;
  tempPageObject: any = {};
  searchVideoObject: any = {};
  customInput: Subject<string> = new Subject();
  pageSizeList: string[] = ["30", "50", "70"];
  selectedPageSize = "30";
  config: any;
  publishedStatus: any = '';
  filterCategoryId: any = '';
  moduleList: any;
  moduleIdList: any = [];
  filterModuleIdList: any = [];
  filterModuleList: any = [];
  selectedWatch: any = {};
  constructor(
    private service: VideoService,
    private topicService: TopicService,
    private toastr: ToastrManager,
    private appService: AppService) {
    this.customInput.pipe(debounceTime(1000), distinctUntilChanged()).subscribe(value => {
      this.searchVideoObject.Name = value;
      this.loadVideos(this.searchVideoObject);
    });
  }

  ngOnInit(): void {
    this.config = {
      itemsPerPage: this.selectedPageSize,
      currentPage: this.pageNumber,
      totalItems: 0
    };
    this.getFilter();
    this.loadVideos(this.searchVideoObject);
    this.getAllModules();
  }

  getAllModules() {
    let data = {};
    data = { 'pageSize': 1000, 'pageIndex': 1, 'categoryType': '', 'searchName': '' };
    this.topicService.getAllTopic(data).subscribe((res: any) => {
      this.moduleList = res.results;
      this.moduleList.forEach(element => {
        if (element.is_published === true) {
          element.status = 'Published';
        } else {
          element.status = 'Draft';
        }
        this.moduleIdList.push(parseInt(element.pk))
      });
    });
  }

  filterModules() {
    this.filterModuleList = this.moduleList.filter(o1 => this.filterModuleIdList.some((o2: any) => o1.pk === o2));
  }

  loadVideos(event: any) {
    this.loader('show');
    let data = {};
    data = {
      'pageSize': this.pageSize, 'pageIndex': this.pageNumber, 'category_id': this.filterCategoryId, 'is_published': this.publishedStatus,
      'searchArticle': this.searchVideoObject ? (this.searchVideoObject.Name ? this.searchVideoObject.Name : '') : ''
    };
    this.service.getAllVideo(data).subscribe((res: any) => {
      this.loader('hide');
      this.videoList = res.results;
      this.videoList.forEach(element => {
        if (element.is_published === true) {
          element.status = 'Published';
        } else {
          element.status = 'Draft';
        }
        this.filterModuleIdList.push(parseInt(element.category_id))
      });
      this.filterModuleIdList = this.filterModuleIdList.filter(function (elem: any, index: any, self: any) {
        return index === self.indexOf(elem);
      })
      this.totalRecords = res.count;
      this.config = {
        itemsPerPage: this.selectedPageSize,
        currentPage: this.pageNumber,
        totalItems: this.totalRecords
      };
      this.setFilter();
    }, err => {
      this.loader('hide');
    });
  }
  searchVideo(event) {
    this.customInput.next(event);
  }
  setSelected(data) {
    this.filterCategoryId = "";
    if (data === '') {
      this.publishedStatus = "";
    } else if (data === 'True') {
      this.publishedStatus = data;
    } else if (data === 'False') {
      this.publishedStatus = data;
    } else {
      this.filterCategoryId = data.pk.toString();
    }
    this.loadVideos(this.searchVideoObject);
  }

  setFilter() {
    this.searchVideoObject.pageNumber = this.pageNumber;
    this.searchVideoObject.pageSize = this.pageSize;
    this.searchVideoObject.publishedStatus = this.publishedStatus;
    this.searchVideoObject.module = 'video';
    localStorage.setItem('searchObject', JSON.stringify(this.searchVideoObject));
  }

  getFilter() {
    var searchObject = JSON.parse(localStorage.getItem('searchObject'));
    if (searchObject !== null) {
      if (searchObject.module === 'video') {
        this.searchVideoObject = searchObject;
        this.pageNumber = this.searchVideoObject.pageNumber;
        this.pageSize = this.searchVideoObject.pageSize;
        this.selectedPageSize = this.searchVideoObject.pageSize;
        this.publishedStatus = this.searchVideoObject.publishedStatus;
      }
    }
  }
  confirmDelete(deleteVideo: any) {
    this.toDeleteVideoId = deleteVideo.pk;
  }
  deleteArticle() {
    this.loader('show');
    const data = { 'article_id': this.toDeleteVideoId };
    this.service.deleteVideo(data).subscribe((res: any) => {
      if (res.success) {
        this.toastr.successToastr('Deleted successfully.');
        $('#deleteModal').modal('hide');
        this.loadVideos(this.tempPageObject);
      }
      this.loader('hide');
    },
      (error) => {
        this.loader('hide');
        this.toastr.errorToastr('Deletion failed.');
      }
    );

  }
  pageChanged(event: any) {
    this.pageNumber = event;
    this.loadVideos(this.tempPageObject);
  }
  onPageSizeChange(event: any) {
    this.pageSize = event;
    this.pageNumber = 1;
    this.loadVideos(this.tempPageObject);
  }
  loader(status: string) {
    this.appService.setloaderVisibility(status);
  }
  viewWatch(item: any) {
    this.selectedWatch = item;
  }
}
