import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ProfileService } from '../profile.service';
import { ToastrManager } from 'ng6-toastr-notifications';
declare var $: any;

@Component({
  selector: 'app-update-profile-modal',
  templateUrl: './update-profile-modal.component.html',
  styleUrls: ['./update-profile-modal.component.css']
})
export class UpdateProfileModalComponent implements OnInit {
  userObject: any = {};
  modal_title: any;
  selectedFile: File;
  email:any;
  isImageUpload: boolean = false;
  @Output() callGetUserDataEvent = new EventEmitter<any>();

  constructor(private service: ProfileService, private toastr: ToastrManager) { }

  ngOnInit(): void {
  }
   
  openUpdateProfile(data){
    this.userObject =data;
    this.modal_title = 'Update Profile';
  }

  updateProfile(){
    const formData : FormData = new FormData();
    if (this.userObject.pk){
      if(this.userObject.is_staff == true){
        formData.append('is_staff', 'True'); 
        this.userObject.is_update_profile = 'False';
      }
      this.userObject.email=this.email;
      formData.append('first_name', this.userObject.first_name); 
      formData.append('last_name', this.userObject.last_name);
      formData.append('email',  this.userObject.email);
      formData.append('username',  this.userObject.username);
      formData.append('is_update_profile',this.userObject.is_update_profile);
      formData.append('phone', this.userObject.phone);
      formData.append('guardian_name', this.userObject.guardian_name);
      formData.append('guardian_phone', this.userObject.guardian_phone); 
      if(this.selectedFile){
        formData.append('file', this.selectedFile,this.selectedFile.name);
      }
      this.service.updateUser(formData).subscribe(data =>{
          console.log(data); // handle event here
        $('#updateProfileModal').modal('hide');
        this.toastr.successToastr('User updated successfully');
        this.callGetUserDataEvent.emit('');
      }, err =>{

      });
      
    }
  }

setEmail(event){
  this.email=event.target.value;

}

onFileChange(event) {
  if (event.target.files && event.target.files[0]) {
    var reader = new FileReader();

    reader.readAsDataURL(event.target.files[0]); // read file as data url

    reader.onload = (event) => { // called once readAsDataURL is completed
      this.userObject.image_url = event.target.result;
      this.isImageUpload = false;
    }
  }
  this.selectedFile = event.target.files[0]
}

removeImage() {
  this.userObject.image_url = "";
  this.isImageUpload = true;
}

}

