import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GroupManagementService {

  constructor(private http: HttpClient) { }

  getAllUser() {
    return this.http.get(environment.BASE_URL + 'user/?page_size=500').pipe(map(res => res));
  }

  getSchools() {
    return this.http.get(environment.BASE_URL + 'user/schools').pipe(map(res => res));
  }

  getAllModules() {
    return this.http.get(environment.BASE_URL + 'topic/category/list?page_size=1000&page=1').pipe(map(res => res));
  }
  
  getAllGroups(data) {
    return this.http.get(environment.BASE_URL + `topic/group?search=${data.search}`);
  }

  updateGroup(data) {
    return this.http.put(environment.BASE_URL + 'topic/group', data).pipe(map(res => res));
  }


  addGroup(data) {
    return this.http.post(environment.BASE_URL + 'topic/group', data).pipe(map(res => res));
  }

  deleteGroup(id) {
    return this.http.delete(environment.BASE_URL + 'topic/group?id=' + id).pipe(map(res => res));
  }
}
