import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { UserQuestionsService } from '../user-questions.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { TopicService } from '../../topic/topic.service';
import { AppService } from "../../../../app.service";
// import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill'

declare var $: any;
@Component({
  selector: 'app-add-user-questions-modal',
  templateUrl: './add-user-questions-modal.component.html',
  styleUrls: ['./add-user-questions-modal.component.css']
})
export class AddUserQuestionsModalComponent implements OnInit {
  modal_title: any;
  topicList: any;
  userQuestionObject: any = {};
  selectedTopic: any = {};
  editorTextContent: any;
 
  questionQuillEditorRef;
  maxUploadFileSize = 1000000;
  @Output() callGetAllUserQuestionDataEvent = new EventEmitter<any>();
  // @Output() callGetEditorContentDataEvent = new EventEmitter<any>();
  constructor(
    private service: UserQuestionsService, 
    private toastr: ToastrManager,
    private topicService: TopicService,
    private appService: AppService) { }

  ngOnInit(): void {
    this.getAllModules();
  }

  getAllModules() {
    
    let data = {};
    data = { 'pageSize': 1000, 'pageIndex': 1, 'categoryType': '', 'searchName': '' };
    this.topicService.getAllTopic(data).subscribe((res: any) => {
      this.topicList = res.results;
      this.topicList.forEach(element => {
        if (element.is_published === true) {
          element.status = 'Published';
        } else {
          element.status = 'Draft';
        }
      });
    }, err => {

    });
  }
  addUserQuestion(data) {
    //this.userQuestionObject = {};
    this.userQuestionObject = data;
    // this.editorTextContent = this.userQuestionObject.answer;
    // this.editorTextContent = "";
    // if(this.questionQuillEditorRef.clipboard){
    //   this.questionQuillEditorRef.clipboard.dangerouslyPasteHTML(this.editorTextContent);
    // }
    this.modal_title = 'Add Answer';
    this.selectedTopic = this.topicList.find(i => i.pk === data.category);
    // this.selectedTopic = this.userQuestionObject.category_id;
  }
  editUserQuestion(data) {
    
    this.userQuestionObject = data;
    // this.editorTextContent = this.userQuestionObject.answer;
    // if (this.userQuestionObject.answer === null) {
    //   this.editorTextContent = "";
    // }
    // this.callGetEditorContentDataEvent.emit(this.editorTextContent);
    // if(this.questionQuillEditorRef.clipboard){
    //   this.questionQuillEditorRef.clipboard.dangerouslyPasteHTML(this.editorTextContent);
    // }
    this.modal_title = 'Edit Answer';
    this.selectedTopic = this.topicList.find(i => i.pk === data.category);
  }
 
  // editorText(data) {
    
  //   this.editorTextContent = data;
  // }

  saveUserQuestion() {
    try {
      this.userQuestionObject.category = this.selectedTopic.pk;
      this.userQuestionObject.category_name = this.selectedTopic.name;
      this.loader('show');
      if (this.userQuestionObject.pk) {
        this.userQuestionObject.question_id = this.userQuestionObject.pk;
        this.userQuestionObject.question = null;
        // this.userQuestionObject.answer = this.editorTextContent;
        this.service.updateUserQuestion(this.userQuestionObject).subscribe(data => {
          $('#addQuestion').modal('hide');
          this.toastr.successToastr('Question updated successfully');
          this.callGetAllUserQuestionDataEvent.emit('');
          this.loader('hide');
        }, err => {
          this.loader('hide');
        });
      } else {
        this.service.addUserQuestion(this.userQuestionObject).subscribe(data => {
          $('#addQuestion').modal('hide');
          this.toastr.successToastr('Question added successfully');
          this.callGetAllUserQuestionDataEvent.emit('');
          this.loader('hide');
        }, err => {
          // this.blockedUI(false);
          this.loader('hide');
        });
      }
    } catch (ex) {
      this.toastr.errorToastr('Please enter valid data');
    }
  }


  // getquestionEditorInstance(editorInstance: any) {
    
  //   this.questionQuillEditorRef = editorInstance;
  //   const toolbar = editorInstance.getModule('toolbar');
  //   toolbar.addHandler('image', this.questionImageHandler);
  // }
  // changedQuestionEditor(event: EditorChangeContent | EditorChangeSelection) {
    
  //   console.log('editor-change', event)
  //  // this.editorTextContent = document.querySelector("#questioneditor.ql-editor").innerHTML;
  //  this.editorTextContent = event.editor.root.innerHTML;
  //   //this.callGetTextEditorDataEvent.emit(this.content);
  // }
  // questionImageHandler = (image, callback) => {
  //   const input = <HTMLInputElement>document.getElementById('questionfileInputField');
  //   document.getElementById('questionfileInputField').onchange = () => {
  //     let file: File;
  //     file = input.files[0];
  //     if (/^image\//.test(file.type)) {
  //       if (file.size > this.maxUploadFileSize) {
  //         alert('Image needs to be less than 1MB');
  //       } else {
  //         const reader = new FileReader();
  //         reader.onload = () => {

  //           const range = this.questionQuillEditorRef.getSelection();
  //           const img = '<img src="' + reader.result + '" width="70" height="70"/>';
  //           this.questionQuillEditorRef.clipboard.dangerouslyPasteHTML(range.index, img);
  //         };
  //         reader.readAsDataURL(file);
  //       }
  //     } else {
  //       alert('You could only upload images.');
  //     }
  //   };

  //   input.click();
  // }

  loader(status: string){
    this.appService.setloaderVisibility(status);
  }
}
