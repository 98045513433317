import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { LoginService } from '../login.service'
import { BlockUI, NgBlockUI } from 'ng-block-ui';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  email: any;
  @BlockUI() blockUI: NgBlockUI;
  constructor(
    private router: Router, private toastr: ToastrManager, private service: LoginService
  ) { }

  ngOnInit(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('app');
  }

  sendEmail() {    
    if (this.email === null || this.email === undefined || this.email === '') {
      this.toastr.infoToastr('Please enter valid email.');
      return;
    }
    this.blockedUI(true);
    const data = { 'username': this.email };
    this.service.forgotPassword(data).subscribe((res: any) => {
      this.blockedUI(false);
      if (res.success) {
        this.toastr.infoToastr('Reset password link sent to your email');
       // this.router.navigate(['check-mail']);
      } else if (res.emailExist) {
        this.toastr.infoToastr('Email not found');
      } else if (res.error) {
        this.toastr.infoToastr('Bad request');
      }
    }, error => {
      this.toastr.errorToastr('error occured');
      this.blockedUI(false);
    });
  }

  blockedUI(value) {
    if (value) {
      this.blockUI.start('');
    } else {
      this.blockUI.stop();
    }
  }

  goToLoginPage(){
    this.router.navigate(['/login']);
  }
}
