import { Component, OnInit } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ResourceService } from "./resource.service";
import { FormGroup, FormControl } from '@angular/forms';
import { AppService } from "../../../app.service";

declare var $: any;

@Component({
  selector: 'app-resource',
  templateUrl: './resource.component.html',
  styleUrls: ['./resource.component.css']
})
export class ResourceComponent implements OnInit {
  resourceList: any;
  totalRecords: any;
  pageNumber: any = 1;
  pageSize: any = 30;
  tempPageObject: any = {};
  formdata;
  searchResourceObject: any = {};
  pageSizeList: string[] = ["30", "50", "70"];
  selectedPageSize = "30";
  config: any;
  resourceId = 1;
  topicList: any = [];
  newResourceObj: any = {
    header: '',
    about: '',
    phone: '',
    email: '',
    website1: '',
    website2: ''
  };
  Status: any = '';
  selectedResource: any = {};
  customInput: Subject<string> = new Subject();
  totalEntries = 0;
  fromEntries = 1;
  toEntries = 1;
  resources = [];
  resourceObject: any = {};

  constructor(
    private service: ResourceService,
    private toastr: ToastrManager,
    private appService: AppService
  ) {
    this.customInput.pipe(debounceTime(1000), distinctUntilChanged()).subscribe(value => {
      this.searchResourceObject.Name = value;
      this.loadResources();
    });
  }

  ngOnInit(): void {
    this.loadResources();
    this.getAllResource();
  }

  defineForm() {
    this.formdata = new FormGroup({
      header: new FormControl(""),
      about: new FormControl(""),
      phone: new FormControl(""),
      email: new FormControl(""),
      website1: new FormControl()
    });
  }

  getAllResource(search?) {
    this.loader('show');
    const DATA = { search: search ? search : '' };
    this.service.getAllResource(DATA).subscribe((res: any) => {
      this.loader('hide');
      this.resources = res.results;
      this.resources.reverse();
      this.totalEntries = res.count;
    });
  }

  createResource() {

    if(!this.newResourceObj.header) {
      this.toastr.warningToastr('Please fill name of the Resource');
      return;
    }

    this.service.addResources(this.newResourceObj).subscribe((res: any) => {
      this.clearAddForm();
      this.getAllResource();
      this.toastr.successToastr(res.details);
    });
    $('#addResources').modal('hide');
  }

  clearAddForm() {
    let name = document.getElementById('addName');
    let desc = document.getElementById('addDescription');
    let phone = document.getElementById('addResourcePhone');
    let email = document.getElementById('addResourceEmail');
    let url = document.getElementById('addResourceBrowserUrl');

    name.innerText = '';
    desc.innerText = '';
    phone.innerText = '';
    email.innerText = '';
    url.innerText = '';
  }

  loadResources() {
    this.loader('show');
    let data = {};
    data = {
      'pageSize': this.pageSize, 'pageIndex': this.pageNumber,
      'searchResource': this.searchResourceObject ? (this.searchResourceObject.resource_name ? this.searchResourceObject.resource_name : '') : ''
    };
    this.service.getAllResource(data).subscribe((res: any) => {
      this.loader('hide');
      this.resourceList = res.results;
    });
  }

  setSelectedResource(data) {
    this.selectedResource = data;
  }

  deleteResource() {
    this.service.deleteResource(this.selectedResource.pk).subscribe((res: any) => {
      this.getAllResource();
      this.toastr.successToastr(res.status);
    });
    $('#deleteResources').modal('hide');
  }

  SaveChanges(name, description, phone_number, email, web_site, webte2) {
    let data = { 'resource_id': this.selectedResource.pk, 'header': name, 'about': description, 'phone': phone_number, 'email': email, 'website1': web_site, 'website2': webte2 };
    let data3 = {
      "resource_id": this.selectedResource.pk,
      "header": name,
      "about": description,
      "phone": phone_number,
      "email": email,
      "website1": web_site,
      "website2": webte2,
    }
    this.service.updateResource(data3).subscribe((res: any) => {
      this.loadResources();
      this.getAllResource();

    });
    $('#editResources').modal('hide');
  }

  searchResource(searchData: string) {
    this.getAllResource(searchData);
  }

  loader(status: string) {
    this.appService.setloaderVisibility(status);
  }
}
