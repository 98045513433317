import {Component, ViewChild} from '@angular/core';
//import { navItems } from '../../_nav';
import { Router } from '@angular/router';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { ChangePasswordModalComponent} from '../../views/admin/profile/change-password-modal/change-password-modal.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent {
  public sidebarMinimized = false;
  //public navItems = navItems;
  @ViewChild(ChangePasswordModalComponent, {static: false})
  changePasswordModalComponent: ChangePasswordModalComponent;

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  changePassword(){
    this.changePasswordModalComponent.changePassword();
  }
  logout()
  {
    
    Cookie.deleteAll();
    this.changePasswordModalComponent.logout();
    // this.router.navigate['login'];
  }
}
