import { Component, OnInit } from '@angular/core';
import { NotificationService } from './notification.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {

  constructor(private notificationService : NotificationService) { }
  notificationList: any;

  ngOnInit(): void {
    this.getNotification();
  }
  getNotification(){
    
    this.notificationService.getAllNotification().subscribe((res: any) => {
      
      this.notificationList = res.results;  
      this.notificationList.forEach(element => {
        element.created_at  = this.convertUTCDateToLocalDate(new Date(element.created_at)).toLocaleString();
      });    
      
    }, err => {
      
    });
  }

  convertUTCDateToLocalDate(date) {
    var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate;   
}
}
