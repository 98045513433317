import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private http: HttpClient) { }

  getUser(userId) {
    return this.http.get(environment.BASE_URL + 'user/profile/'+userId+'/').pipe(map(res => res));
  }

  updateUser(data) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    let options = { headers: headers };
    return this.http.post(environment.BASE_URL + 'user/update', data, options).pipe(map(res => res));
  }
  
  changePassword(data){
    return this.http.post(environment.BASE_URL + 'user/change-password', data).pipe(map(res => res));
  }

}
