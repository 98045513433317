import { BrowserModule } from '@angular/platform-browser';
import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
// import { DropdownModule } from 'primeng/components/dropdown/dropdown';
// import { TableModule} from 'primeng/table';
// import { DialogModule } from 'primeng/dialog';
import { ToastrModule } from 'ng6-toastr-notifications';
//import { ScrollPanelModule } from 'primeng/scrollpanel';
import { CKEditorModule } from 'ng2-ckeditor';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxPaginationModule } from 'ngx-pagination';
import { QuillModule } from 'ngx-quill';
import { NgxSpinnerModule } from "ngx-spinner";
import {TimeAgoPipe} from 'time-ago-pipe';
// import { ContentComponent } from "./views/admin/content/content.component";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { RegisterComponent } from './views/register/register.component';
import { AuthInterceptor } from './views/interceptors/auth-interceptor';
const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';
import { UiSwitchModule } from 'ngx-toggle-switch';
// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BlockUIModule } from 'ng-block-ui';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
import { LoginComponent } from './views/login/login.component';
import { ForgotPasswordComponent } from './views/login/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './views/login/reset-password/reset-password.component';
import { UserComponent } from './views/admin/user/user.component';
import { DashboardComponent } from './views/admin/dashboard/dashboard.component';
import { AddUserModalComponent } from './views/admin/user/add-user-modal/add-user-modal.component';
import { ConfirmDeleteModalComponent } from './views/shared/modal/confirm-delete-modal/confirm-delete-modal.component';
import { ProfileComponent } from './views/admin/profile/profile.component';
import { UpdateProfileModalComponent } from './views/admin/profile/update-profile-modal/update-profile-modal.component';
import { TopicComponent } from './views/admin/topic/topic.component';
import { AddTopicModalComponent } from './views/admin/topic/add-topic-modal/add-topic-modal.component';
import { UploadFileComponent } from './views/shared/component/upload-file/upload-file.component';
import { VideoComponent } from './views/admin/video/video.component';
import { ChangePasswordModalComponent } from './views/admin/profile/change-password-modal/change-password-modal.component';
import { HomeHeaderComponent } from './containers/layout/home-layout/home-header/home-header.component';
import { HomeFooterComponent} from './containers/layout/home-layout/home-footer/home-footer.component';
import { ArticleComponent } from './views/admin/article/article.component';
import { AddArticleModalComponent } from './views/admin/article/add-article-modal/add-article-modal.component';
import { TextEditorComponent } from './views/shared/component/text-editor/text-editor.component';
import { AddVideoModalComponent } from './views/admin/video/add-video-modal/add-video-modal.component';
import { HomeLayoutComponent } from './containers/layout/home-layout/home-layout.component';
import { HomeSidebarComponent } from './containers/layout/home-layout/home-sidebar/home-sidebar.component';
import { NotificationComponent } from './views/notification/notification.component';
import { UserQuestionsComponent } from './views/admin/user-questions/user-questions.component';
import { AddUserQuestionsModalComponent } from './views/admin/user-questions/add-user-questions-modal/add-user-questions-modal.component';
import { QuillTextEditorComponent } from './views/shared/component/quill-text-editor/quill-text-editor.component';
import { AddFaqModalComponent } from './views/admin/user-questions/add-faq-modal/add-faq-modal.component';
import { TechnicalsupportComponent } from './views/admin/technicalsupport/technicalsupport.component';
import { QuizManagerComponent } from './views/admin/quiz-manager/quiz-manager.component';
import { AddQuizMangerComponent } from './views/admin/quiz-manager/add-quiz-manger/add-quiz-manger.component';
import { AddQuestionBankModalComponent } from './views/admin/quiz-manager/add-question-bank-modal/add-question-bank-modal.component';
import { GroupManagementComponent } from "./views/admin/group-management/group-management.component";
import { BadgesComponent } from './views/admin/badges/badges.component';
import { LeaderboardComponent } from './views/admin/leaderboard/leaderboard.component';
import { ResourceComponent } from './views/admin/resource/resource.component';
import { PointsManagementComponent } from "./views/admin/points-management/points-management.component";
import { PledgeComponent } from "./views/admin/pledges/pledge.component";

import { AddPledgeModalComponent } from "./views/admin/pledges/add-pledge-modal/add-pledge-modal.component";
import { ContentComponent } from './views/admin/content/content.component';
import { LoaderComponent } from "./views/shared/component/loader/loader.component";
import { ErrorComponent } from './views/admin/error/error.component';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    BlockUIModule.forRoot(),
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    // DropdownModule,
    // TableModule,
    ToastrModule.forRoot(),

    UiSwitchModule,
    // DialogModule,
    // ScrollPanelModule,
    CKEditorModule,
    NgxDropzoneModule,
    NgxPaginationModule,
    QuillModule.forRoot(),
    NgxSpinnerModule

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    RegisterComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    UserComponent,
    DashboardComponent,
    AddUserModalComponent,
    ConfirmDeleteModalComponent,
    ProfileComponent,
    UpdateProfileModalComponent,
    TopicComponent,
    AddTopicModalComponent,
    UploadFileComponent,
    VideoComponent,
    ChangePasswordModalComponent,
    HomeHeaderComponent,
    ArticleComponent,
    AddArticleModalComponent,
    TextEditorComponent,
    AddVideoModalComponent,
    HomeFooterComponent,
    HomeLayoutComponent,
    HomeSidebarComponent,
    NotificationComponent,
    TimeAgoPipe,
    UserQuestionsComponent,
    AddUserQuestionsModalComponent,
    QuillTextEditorComponent,
    AddFaqModalComponent,
    TechnicalsupportComponent,
    QuizManagerComponent,
    AddQuizMangerComponent,
    AddQuestionBankModalComponent,
    GroupManagementComponent,
    BadgesComponent,
    LeaderboardComponent,
    ResourceComponent,
    PointsManagementComponent,
    PledgeComponent,
    AddPledgeModalComponent,
    ContentComponent,
    LoaderComponent,
    ErrorComponent
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
    provide: LocationStrategy,
    useClass: HashLocationStrategy
  }],
  bootstrap: [ AppComponent ]
  
})
export class AppModule { }
