import { Component, OnInit ,ViewChild} from '@angular/core';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { ToastrManager } from 'ng6-toastr-notifications';
import { AppService } from '../../../app.service';
import { ProfileService } from './profile.service';
import { UpdateProfileModalComponent } from './update-profile-modal/update-profile-modal.component';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  currentUser: any = {};
  currentUserId:any;

  @ViewChild(UpdateProfileModalComponent, {static: false})
  updateProfileModalComponent: UpdateProfileModalComponent;
  constructor(private service:ProfileService, private toastr: ToastrManager, private appService: AppService) { }

  ngOnInit(): void {
    
   // this.currentUser = JSON.parse(Cookie.get('currentUser'));
    this.currentUserId = Cookie.get('userId')
    this.getUserProfile();
    this.appService.updatedcurrentUser.subscribe(message => this.currentUser = message)

  }

  getUserProfile(){
    this.service.getUser(this.currentUserId).subscribe((res: any) => {
      if(res){
        this.currentUser = res;
        this.appService.getcurrentUser(res)
      }
    },
    () => {
      this.toastr.infoToastr('Invalid info');
    });

  }

  updateProfile()
  {
    this.updateProfileModalComponent.openUpdateProfile(this.currentUser);
  }
}
