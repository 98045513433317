import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { QuizManagerService } from '../quiz-manager.service';
import { TopicService } from '../../topic/topic.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AddQuestionBankModalComponent } from '../add-question-bank-modal/add-question-bank-modal.component';
import { ActivatedRoute } from '@angular/router';
import { sortArrByDate } from "../../../shared/utility-functions";
import { AppService } from "../../../../app.service";

declare var $: any;
@Component({
  selector: 'app-add-quiz-manger',
  templateUrl: './add-quiz-manger.component.html',
  styleUrls: ['./add-quiz-manger.component.css']
})
export class AddQuizMangerComponent implements OnInit {
  questionList: any = [];
  searchQuestionObject: any = {};
  pageNumber: any = 1;
  pageSize: any = 30;
  quizType: any = '';
  totalRecords: any;
  config: any;
  pageSizeList: string[] = ["30", "50", "70"];
  selectedPageSize = "30";
  topicList: any;
  quizObject: any = {};
  quizTypeList: string[] = ["Easy", "Medium", "Hard"];
  selectedQuizType = "";
  file: any = {};
  isImageUpload: boolean = false;
  imageUrl: string | ArrayBuffer = "";
  fileName: string = "No file selected";
  fileRemove: boolean = false;
  customInput: Subject<string> = new Subject();
  selectedItems: any = null;
  associatedQuestionList: any = [];
  toAddedDBassociatedQuestionList: any = [];
  toQuestionAdd = false;
  modal_title: any;
  allSearchQuestions: any = [];
  originalQuizQuestions: any = [];
  @ViewChild(AddQuestionBankModalComponent, { static: false })
  addQuestionBankModalComponent: AddQuestionBankModalComponent;
  constructor(
    private router: Router,
    private service: QuizManagerService,
    private toastr: ToastrManager,
    private topicService: TopicService,
    private route: ActivatedRoute,
    private appService: AppService
  ) {
    this.customInput.pipe(debounceTime(1000), distinctUntilChanged()).subscribe(value => {
      this.searchQuestionObject.Name = value;
      this.loadQuestionBanks(this.searchQuestionObject);
    });
    this.route.params.subscribe(params => {
      if (params.id) {
        this.getQuizById(params.id);
      } else {
        this.modal_title = 'Add Quiz';
      }
    });
  }

  ngOnInit(): void {
    this.config = {
      itemsPerPage: this.selectedPageSize,
      currentPage: this.pageNumber,
      totalItems: 0
    };
    this.getAllModules();
    this.loadQuestionBanks(this.searchQuestionObject);
  }

  getQuizById(id: any) {
    this.loader('show');
    this.service.getQuizByID(id).subscribe((res: any) => {
      if (res.result?.length) {
        this.modal_title = 'Edit Quiz';
        this.quizObject = res.result[0];
         this.loadQuestionBanks(this.searchQuestionObject);
        if (this.quizObject.image !== '' && this.quizObject.image !== null) {
          this.isImageUpload = true;
          // this.imageUrl = environment.BASE_URL.replace('/api/','') + this.quizObject.image;
        }
        if (this.quizObject.quiz_start_date) {
          this.quizObject.quiz_start_date = this.quizObject.quiz_start_date.slice(0, 10);
        }
        if (this.quizObject.quiz_end_date) {
          this.quizObject.quiz_end_date = this.quizObject.quiz_end_date.slice(0, 10);
        }
        this.quizObject.questions?.forEach((element: any) => {
          this.originalQuizQuestions.push(element);
          this.toAddedDBassociatedQuestionList.push(element);
        });
        this.selectedQuizType = this.quizObject.quiz_type;
        this.toAddedDBassociatedQuestionList = sortArrByDate(this.toAddedDBassociatedQuestionList, 'updated_at');
      }
      this.loader('hide');
    }, err => {
      this.loader('hide');
    });
  }
  onFileChange(file: File) {
    if (file) {
      this.fileName = file.name;
      this.file[0] = file;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = event => {
        this.imageUrl = reader.result;
        this.isImageUpload = true;
        this.fileRemove = false;
      };
    }
  }
  removeImage() {
    this.file = {};
    this.isImageUpload = false;
    this.imageUrl = '';
    this.fileRemove = true;
  }
  getAllModules() {
    let data = {};
    data = { 'pageSize': 1000, 'pageIndex': 1, 'categoryType': '', 'searchName': '' };
    this.topicService.getAllTopic(data).subscribe((res: any) => {
      this.topicList = res.results;
      this.topicList.forEach(element => {
        if (element.is_published === true) {
          element.status = 'Published';
        } else {
          element.status = 'Draft';
        }
      });
    });
  }
  searchQuestion(event) {
    this.customInput.next(event);
  }
  pageChanged(event) {
    this.pageNumber = event;
    this.loadQuestionBanks(this.searchQuestionObject);
    this.config.currentPage = event;
  }
  onPageSizeChange(event) {
    this.pageSize = event;
    this.pageNumber = 1;
    this.loadQuestionBanks(this.searchQuestionObject);
  }
  setSelected(data) {
    if (data === 'All') {
      this.quizType = ''
    } else {
      this.quizType = data;
    }
    this.loadQuestionBanks(this.searchQuestionObject);
  }

  clearChecks() {
    $('input[type=checkbox]').each(function () {
      this.checked = false;
    });
  }

  checked(question: any) {
    if (!this.associatedQuestionList.length) {
      // this.clearChecks();
      return;
    }
    let questionList = this.associatedQuestionList.map((a: any) => a.pk);
    if (questionList.includes(question.pk)) {
      return true
    } else {
      return false
    }
  }

  getQuestionChecked(questionToassociate) {
    this.associatedQuestionList.forEach(element => {
      if (element.pk == questionToassociate.pk) {
        this.toQuestionAdd = true;
      }
    });
    if (this.toQuestionAdd === true) {
      this.associatedQuestionList = this.associatedQuestionList.filter((a: any) => a.pk != questionToassociate.pk);
    } else {
      this.associatedQuestionList.push(questionToassociate);
    }
    this.toQuestionAdd = false;
  }

  removeAssociatedQuestion(item: any) {
    this.associatedQuestionList = this.associatedQuestionList.filter((ques: any) => ques.pk != item.pk);
    let removeFromAssociated = this.associatedQuestionList.find((ques: any) => ques.pk == item.pk);
    if (removeFromAssociated) {
      this.resetQuestionList();
    } else {
      this.toAddedDBassociatedQuestionList = this.toAddedDBassociatedQuestionList.filter((ques: any) => ques.pk != item.pk);
    }
  }

  resetQuestionList() {
    this.toAddedDBassociatedQuestionList = [...this.originalQuizQuestions];
    let toquestions = [];
    toquestions = sortArrByDate(this.toAddedDBassociatedQuestionList, 'updated_at').reverse();
    this.associatedQuestionList.forEach((element: any) => {
      toquestions.push(element);
    });
    this.toAddedDBassociatedQuestionList = toquestions.reverse();
  }

  addQuestionFromQUestionBank() {
    this.resetQuestionList();
    $('#addQuizQuestion').modal('hide');
  }

  addQuestion() {
    this.addQuestionBankModalComponent.addQuestionBank(true, { id: this.quizObject.quiz_id, name: this.quizObject.name });
  }

  public newAddedQuestion(data: any) {
    this.toAddedDBassociatedQuestionList.push(data);
    this.toAddedDBassociatedQuestionList = sortArrByDate(this.toAddedDBassociatedQuestionList, 'updated_at');
  }

  loadQuestionBanks(event?: any) {
    // this.getQuizById(params.id);
 
    let data = {};
    data = {
      'pageSize': this.pageSize, 'pageIndex': this.pageNumber, 'difficulty_type': this.quizType, 'category_id': this.quizObject.quiz_id,
      'searchArticle': this.searchQuestionObject ? (this.searchQuestionObject.Name ? this.searchQuestionObject.Name : '') : ''
    };

    this.service.getAllQuestion(data).subscribe((res: any) => {
      let resQuesIds = res.results?.map(a => a.pk);
      let questionList = res.results;
      this.toAddedDBassociatedQuestionList.forEach((element: any) => {
        if (resQuesIds.includes(element.pk)) {
          questionList = questionList.filter((ques: any) => ques.pk != element.pk);
        }
      });
      // let pkArr = [];
      // this.toAddedDBassociatedQuestionList.forEach((quizQues:any) => {
      //   questionList.forEach((bankQues:any) => {
      //     if(quizQues.pk !== bankQues.pk && !pkArr.includes(bankQues.pk)){
      //       bankQues.isAnswer = bankQues.answer ? true : false;
      //       this.questionList.push(bankQues);
      //       pkArr.push(bankQues.pk);
      //     }
      //   });
      // });

      this.questionList = questionList;
      this.questionList.forEach(element => {
        element.isAnswer = false;
        if (element.answer) {
          element.isAnswer = true;
        }
      });
      this.totalRecords = res.count;
      this.config = {
        itemsPerPage: this.selectedPageSize,
        currentPage: this.pageNumber,
        totalItems: this.totalRecords
      };
    }, err => {

    });
  }
  saveQuiz() {
    try {
      this.associatedQuestionList = [];
      this.quizObject.type = 'Quizz';
      this.quizObject.quiz_type = '';
      if (!this.quizObject.time_frame) {
        this.quizObject.time_frame = 0;
      }
      this.quizObject.is_published = true;
      this.quizObject.questionList = [];
      this.toAddedDBassociatedQuestionList.forEach(element => {
        this.quizObject.questionList.push(element.pk)
      });
      //this.quizObject.questionList = this.toAddedDBassociatedQuestionList;
      if (this.quizObject.quiz_id) {
        this.quizObject.category_id = this.quizObject.quiz_id;
        this.service.updateQuiz(this.quizObject).subscribe(data => {

          let fileData = { 'file': this.file[0], 'data': data, 'fileRemove': this.fileRemove }
          if (fileData.file || this.fileRemove) {
            this.service.addTopicFile(fileData).subscribe(res => {

              this.toastr.successToastr('Quiz updated successfully');
              this.router.navigate(['quiz-manager']);
            });
          } else {
            $('#addTopicModal').modal('hide');
            this.toastr.successToastr('Quiz updated successfully');
            this.router.navigate(['quiz-manager']);
          }

        }, err => {

        });

      } else {
        this.quizObject.is_refresh_quizz = false;
        this.service.addQuiz(this.quizObject).subscribe(data => {
          let fileData = { 'file': this.file[0], 'data': data, 'fileRemove': this.fileRemove }
          if (fileData.file) {
            this.service.addTopicFile(fileData).subscribe(res => {
              this.toastr.successToastr('Quiz added successfully');
              this.router.navigate(['quiz-manager']);
            });
          } else {
            this.toastr.successToastr('Quiz added successfully');
            this.router.navigate(['quiz-manager']);
          }
        });
      }
    } catch (ex) {
      this.toastr.errorToastr('Please enter valid data');
    }
  }

  searchEditQuiz(data: any) {
    data = data.trim();
    let quesList = this.toAddedDBassociatedQuestionList;
    if (!this.allSearchQuestions.length) {
      this.allSearchQuestions = quesList;
    }
    quesList = this.allSearchQuestions;
    if (!data) {
      this.toAddedDBassociatedQuestionList = this.allSearchQuestions;
      return
    }
    let newList = quesList.filter((item: any) => {
      if (
        item.title.toLowerCase().includes(data.toLowerCase()) ||
        item.difficulty_type.toLowerCase().includes(data.toLowerCase())
      ) {
        return item;
      }
    });
    this.toAddedDBassociatedQuestionList = newList;
  }

  loader(status: string) {
    this.appService.setloaderVisibility(status);
  }
}
