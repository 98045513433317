import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  currentUser: any = {};
  constructor(private router: Router) { }

  private messageSource = new BehaviorSubject(this.router.url);
  currentMessage = this.messageSource.asObservable();


  private currentUserSource = new BehaviorSubject({});
  updatedcurrentUser = this.currentUserSource.asObservable();


  getcurrentUser(message:any) {
    this.currentUserSource.next(message)
  }

  changeMessage(message: string) {
    this.messageSource.next(message)
  }

  private loaderStatus = new BehaviorSubject('hide');
  showLoader = this.loaderStatus.asObservable();

  setloaderVisibility(visibility: string){
    this.loaderStatus.next(visibility);
  }
}