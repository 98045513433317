import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { TopicService } from '../topic.service';
import { AppService } from "../../../../app.service";
declare var $: any;
enum CheckBoxType { Learn, Watch, Questions, Quizz };

@Component({
  selector: 'app-add-topic-modal',
  templateUrl: './add-topic-modal.component.html',
  styleUrls: ['./add-topic-modal.component.css']
})
export class AddTopicModalComponent implements OnInit {
  modal_title: any;
  topicObject: any = {};
  check_box_type = CheckBoxType;
  currentlyChecked: CheckBoxType;
  file: any = {};
  isImageUpload: boolean = false;
  imageUrl: string | ArrayBuffer = "";
  fileName: string = "No file selected";
  fileRemove: boolean = false;
  @Output() callGetAllTopicDataEvent = new EventEmitter<any>();

  constructor(
    private router: Router, 
    private service: TopicService, 
    private toastr: ToastrManager,
    private appService: AppService
    ) { }

  ngOnInit(): void { }

  addTopic() {
    this.topicObject = {};
    this.topicObject.is_published = true;
    this.modal_title = 'Add Module';
  }

  editTopic(data) {
    
    this.imageUrl = '';
    this.topicObject = data;
    if (data.category_type === 'Learn') {
      this.currentlyChecked = 0;
    } else if (data.category_type === 'Watch') {
      this.currentlyChecked = 1;
    } else if (data.category_type === 'Questions') {
      this.currentlyChecked = 2;
    } else if (data.category_type === 'Quizz') {
      this.currentlyChecked = 3;
    }
    if (this.topicObject.image !== '' && this.topicObject.image !== null) {
      this.isImageUpload = true;
      this.imageUrl = this.topicObject.image;
    }
    this.modal_title = 'Edit Module';
  }
  onFileChange(file: File) {
    if (file) {
      this.fileName = file.name;
      this.file[0] = file;

      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = event => {
        this.imageUrl = reader.result;
        this.isImageUpload = true;
        this.fileRemove = false;
      };
    }
  }
  removeImage() {
    this.file = {};
    this.isImageUpload = false;
    this.imageUrl = '';
    this.fileRemove = true;
  }
  selectCheckBox(targetType: CheckBoxType) {
    
    if (this.currentlyChecked === targetType) {
      this.currentlyChecked = CheckBoxType.Learn;
      return;
    }
    this.currentlyChecked = targetType;
  }
  saveTopic() {
    try {
      this.loader('show');     
      this.topicObject.parent_id = null;
      this.topicObject.type = CheckBoxType[this.currentlyChecked];
      if (this.topicObject.pk) {
        this.topicObject.category_id = this.topicObject.pk;
        this.service.updateTopic(this.topicObject).subscribe(data => {
          let fileData = { 'file': this.file[0], 'data': data, 'fileRemove': this.fileRemove }
          if (fileData.file || this.fileRemove) {
            this.service.addTopicFile(fileData).subscribe(res => {
              $('#addTopicModal').modal('hide');
              this.toastr.successToastr('Module updated successfully');
              this.callGetAllTopicDataEvent.emit('');
            });
          } else {
            $('#addTopicModal').modal('hide');
            this.toastr.successToastr('Module updated successfully');
            this.callGetAllTopicDataEvent.emit('');
          }
          this.loader('hide');
        }, err => {
          this.loader('hide');
        });
      } else {
        this.service.createTopic(this.topicObject).subscribe(data => {
          let fileData = { 'file': this.file[0], 'data': data, 'fileRemove': this.fileRemove }
          if (fileData.file) {
            this.service.addTopicFile(fileData).subscribe(res => {
              $('#addTopicModal').modal('hide');
              this.toastr.successToastr('Module added successfully');
              this.callGetAllTopicDataEvent.emit('');
            });
          } else {
            $('#addTopicModal').modal('hide');
            this.toastr.successToastr('Module added successfully');
            this.callGetAllTopicDataEvent.emit('');
          }
          this.loader('hide');
        }, err => {
          this.loader('hide');
        });
      }
    } catch (ex) {
      this.toastr.errorToastr('Please enter valid data');
    }
  }

  loader(status: string){
    this.appService.setloaderVisibility(status);
  }
}
