import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../../../../app.service';

@Component({
  selector: 'app-home-sidebar',
  templateUrl: './home-sidebar.component.html',
  styleUrls: ['./home-sidebar.component.css']
})
export class HomeSidebarComponent implements OnInit {

   selectedUrl:string;

  constructor(private router: Router,private appService: AppService) { }

  ngOnInit(): void {
    this.selectedUrl = this.router.url;
    this.appService.currentMessage.subscribe(url => {
      this.selectedUrl = url;
    })

  }
  setSelected(url:string){
    this.selectedUrl = url;
  }
}
