import { Component, OnInit, ViewChild } from '@angular/core';
import { AddTopicModalComponent } from '../topic/add-topic-modal/add-topic-modal.component';
import { TopicService } from './topic.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AppService } from "../../../app.service";
enum CheckBoxType { Learn, Watch, Questions, Quizz };
declare var $:any;

@Component({
  selector: 'app-topic',
  templateUrl: './topic.component.html',
  styleUrls: ['./topic.component.css']
})
export class TopicComponent implements OnInit {
  topicList: any;
  cols: any[];
  totalRecords: any;
  toDeleteTopicId:any;
  pageNumber: any = 1;
  pageSize: any = 30;
  tempPageObject: any = {};
  searchTopicObject: any = {};
  pageSizeList: string[] = ["30", "50","70"];
  selectedPageSize = "30";
  config: any;
  categoryType: any ='';
  private topicFilter: string[] = ["All", "Learn", "Watch", "Questions", "Quizz" ];
  selectedFilter = "All";
  customInput: Subject<string> = new Subject();
  @ViewChild(AddTopicModalComponent, {static: false})
  addTopicModalComponent: AddTopicModalComponent;

  constructor(
    private service:TopicService,
    private toastr:ToastrManager,
    private appService: AppService
    ) { 
    this.customInput.pipe(debounceTime(1000), distinctUntilChanged()).subscribe(value => {
      this.searchTopicObject.Name = value;
      this.loadTopics(this.searchTopicObject);
    });
  }

  ngOnInit(): void {
    this.config = {
      itemsPerPage: this.selectedPageSize,
      currentPage: this.pageNumber,
      totalItems: 0
    };
    this.getFilter();
    this.loadTopics(this.searchTopicObject);
  }
  loadTopics(event) {
    this.loader('show');
    let data = {};
    data = { 'pageSize': this.pageSize, 'pageIndex': this.pageNumber, 'categoryType': this.categoryType,
     'searchName': this.searchTopicObject ? ( this.searchTopicObject.Name ? this.searchTopicObject.Name : '' ): '' };
    this.service.getAllTopic(data).subscribe((res: any) => {
      this.topicList = res.results;  
      this.topicList.forEach(element => {
        if(element.is_published === true){
          element.status = 'Published';
        } else{
          element.status = 'Draft';
        }
      });    
      this.totalRecords = res.count;
      this.config = {
        itemsPerPage: this.selectedPageSize,
        currentPage: this.pageNumber,
        totalItems: this.totalRecords
      };
      this.setFilter();
      this.loader('hide');
    }, err => {
      this.loader('hide');
    });
  }

  setFilter(){
    this.searchTopicObject.pageNumber = this.pageNumber;
    this.searchTopicObject.pageSize = this.pageSize;
    this.searchTopicObject.categoryType = this.categoryType;
    this.searchTopicObject.module = 'topic';
    localStorage.setItem('searchObject', JSON.stringify(this.searchTopicObject));
  }
  getFilter(){
    
    var searchObject = JSON.parse(localStorage.getItem('searchObject'));
    if( searchObject !== null){
      if(searchObject.module === 'topic'){
        this.searchTopicObject = searchObject;
        this.pageNumber =this.searchTopicObject.pageNumber ;
        this.pageSize = this.searchTopicObject.pageSize ;
        this.selectedPageSize = this.searchTopicObject.pageSize ;
        this.categoryType= this.searchTopicObject.categoryType ;
      }
    }  
  }
  pageChanged(event){
    
    this.pageNumber =event;
    this.loadTopics(this.searchTopicObject);   
  }
  onPageSizeChange(event){
    
    this.pageSize =event;
    this.pageNumber =1;
    this.loadTopics(this.searchTopicObject);   
  }
  searcTopic(event) {
    this.customInput.next(event);
  }
  setSelected(text:string){
    
    this.selectedFilter = text;
    this.categoryType = text;
    this.loadTopics(this.searchTopicObject);
  }
  addTopic() {
    
    this.addTopicModalComponent.addTopic();
  }
  editTopic(data){
    
    this.addTopicModalComponent.editTopic(data);    
  }
  confirmDelete(deleteTopic){
    
    this.toDeleteTopicId = deleteTopic.pk;
  }
  deleteTopic(){
    this.loader('show');
    const data = { 'category_id' : this.toDeleteTopicId, 'parent_id' : null};
    this.service.deleteTopic(data).subscribe((res: any) => {
        if(res.success){
          this.toastr.successToastr('Deleted successfully.');
          $('#deleteModal').modal('hide');
          this.loadTopics(this.tempPageObject);
        } 
        this.loader('hide');
      },
      (error) => {
        this.loader('hide');
        this.toastr.errorToastr('Module Can not be deleted because of associated activities');
      }
    );
   
  }

  loader(status: string){
    this.appService.setloaderVisibility(status);
  }
}
