import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  constructor(private http: HttpClient) { }

  // getAllResource(data) {
  //   //return this.http.get(environment.BASE_URL + 'topic/question/list?search=' + data.search).pipe(map(res => res));
  //   
  //   return this.http.get(environment.BASE_URL + 'topic/resource/list?page_size='+data.pageSize +'&page='+ data.pageIndex + '&search=' + data.search +'&category_id=' + data.category_id + '&faq=' +data.faq).pipe(map(res => res));
  
  // }

  getAllLogError(data) {
     return this.http.get(environment.BASE_URL + 'topic/error/list?search='+data.search).pipe(map(res => res));
   // return this.http.get(environment.BASE_URL + `topic/resource/list?page_size=${data.pageSize}&page=${data.pageNo}&search=${data.search}`);
  }
  // addUserResource(data) {
  //   return this.http.post(environment.BASE_URL + 'topic/resource/', data).pipe(map(res => res));
  // }
  addLogErrors(data) {
    return this.http.post(environment.BASE_URL + 'topic/error', data).pipe(map(res => res));
  }
//   updateResource(data) {
//     return this.http.put(environment.BASE_URL + 'topic/resource',data).pipe(map(res => res));
//   }
deleteLogError(pk: any) {
    return this.http.delete(environment.BASE_URL + 'topic/error?logerrors_id='+pk).pipe(map(res => res));
  }
}