import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class UserQuestionsService {
  constructor(private http: HttpClient) { }

  getAllUserQuestion(data) {
    //return this.http.get(environment.BASE_URL + 'topic/question/list?search=' + data.search).pipe(map(res => res));
    return this.http.get(environment.BASE_URL + 'topic/question/list?page_size='+data.pageSize +'&page='+ data.pageIndex  + '&answer='+ data.answer + '&search=' + data.search +'&category_id=' + data.category_id + '&faq=' +data.faq).pipe(map(res => res));
  }
  addUserQuestion(data) {
    return this.http.post(environment.BASE_URL + 'topic/question', data).pipe(map(res => res));
  }
  updateUserQuestion(data) {
    return this.http.put(environment.BASE_URL + 'topic/question', data).pipe(map(res => res));
  }
  deleteUser(userQuestionId) {
    return this.http.delete(environment.BASE_URL + 'topic/question?id=' + userQuestionId).pipe(map(res => res));
  }
}
