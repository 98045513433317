import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BadgesService {

  constructor(private http: HttpClient) { }

  getAllModules() {
    return this.http.get(environment.BASE_URL + 'topic/category/list?page_size=1000&page=1').pipe(map(res => res));
  }

  addBadge(data) {
    return this.http.post(environment.BASE_URL + 'topic/badge', data).pipe(map(res => res));
  }

  getAllBadges(data) {
    return this.http.get(environment.BASE_URL + 'badge/list' + `?search=${data.search}`).pipe(map(res => res));
  }

  updateBadge(data) {
    return this.http.put(environment.BASE_URL + 'topic/badge', data).pipe(map(res => res));
  }

  deleteBadge(id) {
    return this.http.delete(environment.BASE_URL + 'topic/badge?badge_id=' + id).pipe(map(res => res));
  }

  addBadgeFile(fileData: any){
    let file: File = fileData.file;
    let formData: FormData = new FormData();
    if(file){
      formData.append('file', file, file.name); 
    }
      formData.append('badge_id', fileData.data.badge_id);
      formData.append('url', fileData.data.url);
      formData.append('fileRemove', fileData.fileRemove);
      let headers = new HttpHeaders();
      headers.append('Content-Type', 'multipart/form-data');
      headers.append('Accept', 'application/json');
      let options = { headers: headers };
      return this.http.post(environment.BASE_URL + 'topic/badge/image', formData, options).pipe(map(res => res));
  }

}
