import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
declare var $:any;

@Injectable({
  providedIn: 'root'
})
export class QuizManagerService {

  constructor(private http: HttpClient) { }
  getAllQuestion(data){
    return this.http.get(environment.BASE_URL + 'questionbank/list?page=' + data.pageIndex + '&page_size=' + data.pageSize + 
      '&article_type=question&search_article=' + data.searchArticle +'&difficulty_type=' + data.difficulty_type +
        '&category_id=' + data.category_id ).pipe(map(res => res));
  }
  addQuestionBank(data) {    
    return this.http.post(environment.BASE_URL + 'questionbank', data).pipe(map(res => res));
  }
  updateQuestionBank(data){
    return this.http.put(environment.BASE_URL + 'questionbank', data).pipe(map(res => res));
  }
  deleteQuestionBank(id){
    return this.http.delete(environment.BASE_URL + 'questionbank?id='+ id).pipe(map(res => res));
  } 
  getAllQuiz(data){
    if(data.quiz_type){
      return this.http.get(environment.BASE_URL + 'quizz?page=' + data.pageIndex + '&page_size=' + data.pageSize + '&quiz_type='+data.quiz_type).pipe(map(res => res));
    }else if(data.search_quiz){
      return this.http.get(environment.BASE_URL + 'quizz?page=' + data.pageIndex + '&page_size=' + data.pageSize + '&search_quiz='+data.search_quiz).pipe(map(res => res));
    }else if(data.referesher_quiz){
      return this.http.get(environment.BASE_URL + 'quizz?page=' + data.pageIndex + '&page_size=' + data.pageSize + '&referesher_quiz='+data.referesher_quiz).pipe(map(res => res));
    }else{
      return this.http.get(environment.BASE_URL + 'quizz?page=' + data.pageIndex + '&page_size=' + data.pageSize ).pipe(map(res => res));
    }
  }
  addQuiz(data) {    
    return this.http.post(environment.BASE_URL + 'quizz', data).pipe(map(res => res));
  }
  updateQuiz(data){
    return this.http.put(environment.BASE_URL + 'quizz', data).pipe(map(res => res));
  }
  deleteQuiz(id){
    return this.http.delete(environment.BASE_URL + 'quizz?id='+ id).pipe(map(res => res));
  } 
  addTopicFile(fileData){
    let file: File = fileData.file;
    let formData: FormData = new FormData();
    if(file){
      formData.append('file', file, file.name); 
    }
      formData.append('category_id', fileData.data.quiz_id);
      formData.append('url', fileData.data.url);
      formData.append('fileRemove', fileData.fileRemove);
      let headers = new HttpHeaders();
      headers.append('Content-Type', 'multipart/form-data');
      headers.append('Accept', 'application/json');
      let options = { headers: headers };
    return this.http.post(environment.BASE_URL + 'topic/category/icon', formData, options).pipe(map(res => res));

  }
  getQuizByID(id){
    return this.http.get(environment.BASE_URL + 'quizz?id='+ id).pipe(map(res => res));
  }
  getLearnTopics() {
    return this.http.get(environment.BASE_URL + 'topic/article/list?page_size=500&article_type=image').pipe(map(res => res));
  }
  getWatchTopics() {
    return this.http.get(environment.BASE_URL + 'topic/article/list?page_size=500&article_type=video').pipe(map(res => res));
  }

}