import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { LoginService } from '../login.service';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  newPassword: any;
  confirmPassword: any;
  token: any;
  newPasswordfieldTextType: boolean;
  confirmPasswordfieldTextType:boolean;
  @BlockUI() blockUI: NgBlockUI;
  constructor(private route: ActivatedRoute, private router: Router, private service: LoginService, private toastr: ToastrManager) {
    this.route.queryParams.subscribe(params => {
      this.token = params['token'];
    });
  }

  ngOnInit(): void {
  }
  reset() {
    
    if ((this.newPassword === "" && this.confirmPassword === "") || (this.newPassword === undefined && this.confirmPassword === undefined))   {
      this.toastr.infoToastr('Please enter passwords.');
      return;
    }else if (this.newPassword === null || this.newPassword === undefined || this.newPassword === "")  {
      this.toastr.infoToastr('Please enter new password.');
      return;
    } else if (this.confirmPassword === null || this.confirmPassword === undefined || this.confirmPassword === "") {
      this.toastr.infoToastr('Please enter confirm password.');
      return;
    } else if (this.newPassword !== this.confirmPassword) {
      this.toastr.infoToastr('Password does not match.');
      return;
    }
    this.blockedUI(true);
    const data = { 'token': this.token, 'password': this.newPassword };
    this.service.resetPassword(data).subscribe((res: any) => {
      this.blockedUI(false);
      if (res.success) {
        this.toastr.successToastr('Password reset successfully');
        this.router.navigate(['login']);
      } else if (res.token_expire) {
        this.toastr.infoToastr('Link expired');
      } else if (res.invalid_token) {
        this.toastr.infoToastr('Invalid link');
      } else {
        this.toastr.errorToastr('error occured');
      }
    }, error => {
      this.toastr.errorToastr('error occured');
      this.blockedUI(false);
    });
  }
  newPasswordToggle() {
    
    this.newPasswordfieldTextType = !this.newPasswordfieldTextType;
  }
  confirmPasswordToggle() {
    this.confirmPasswordfieldTextType = !this.confirmPasswordfieldTextType;
  }
   
  blockedUI(value) {
    if (value) {
      this.blockUI.start('');
    } else {
      this.blockUI.stop();
    }
  }
}
