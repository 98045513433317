import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private http: HttpClient) { }

  getAllNotification() {
    return this.http.get(environment.BASE_URL + 'topic/notification/list?operation=admin&status=True').pipe(map(res => res));
  }

  deleteNotification(id) {
    return this.http.delete(environment.BASE_URL + 'topic/notification?notification_id='+ id).pipe(map(res => res));
  }
}
