import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { LeaderBoardService } from "./leaderboard.service";
import { debounce as _debounce } from 'lodash';
import { AppService } from "../../../app.service";

declare var $: any;

@Component({
  selector: 'app-leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.css']
})

export class LeaderboardComponent implements OnInit {
  leaderboardList: any;
  totalRecords: any;
  pageNumber: any = 1;
  pageSize: any = 30;
  tempPageObject: any = {};
  formdata: any;
  searchLeaderBoardObject: any = {};
  pageSizeList: string[] = ["30", "50", "70"];
  selectedPageSize = "30";
  config: any;
  leaderboardId = 1;
  topicList: any = [];
  newLeaderBoardObj: any = {};
  Status: any = '';
  selectedLeaderBoard: any = {};
  customInput: Subject<string> = new Subject();
  totalEntries = 0;
  fromEntries = 1;
  toEntries = 1;
  leaderboards = [];
  leaderboardsNew = [];
  uniqueNames = [];
  schools = [];
  leaderboardObject: any = {};

  constructor(
    private service: LeaderBoardService,
    private toastr: ToastrManager,
    private appService: AppService
  ) {
    this.customInput.pipe(debounceTime(1000), distinctUntilChanged()).subscribe(value => {
      this.searchLeaderBoardObject.name = value;
      this.loadLeaderBoards();
    });
  }

  ngOnInit(): void {
    this.getAllLeaderBoard();
    this.getAllDistictSchool();
  }

  getAllLeaderBoard(schoolId?) {
    this.loader('show');
    const DATA = { schoolId: schoolId ? schoolId : '' };
    this.service.getAllLeaderBoard(DATA).subscribe((res: any) => {
      this.loader('hide');
      this.leaderboards = res;
      this.totalEntries = res.count;
    }, err => {
      this.loader('hide');
    });
  }

  getAllDistictSchool() {
    this.service.getAllDistictSchool().subscribe((ress: any) => {
      this.leaderboardsNew = ress;
      this.totalEntries = ress.count;
      var i;
      for (i = 0; i < ress.length; i++) {
        if (this.uniqueNames.indexOf(ress[i].school_id) === -1) {
          this.uniqueNames.push(ress[i].school_id);
          this.schools.push({ id: ress[i].school_id, name: ress[i].school })
        }
      }
    });
  }

  loadLeaderBoards(schoolId?) {
    this.loader('show');
    let data = {};
    data = {
      'pageSize': this.pageSize, 'pageIndex': this.pageNumber,
      'schoolId': schoolId ? schoolId : ''
    };
    this.service.getAllLeaderBoard(data).subscribe((res: any) => {
      this.loader('hide');
      this.leaderboardList = res;
    }, err => {
      this.loader('hide');
    });
  }

  searchLeaderBoard(searchData: string) {
    this.getAllLeaderBoard(searchData);
  }

  loader(status: string) {
    this.appService.setloaderVisibility(status);
  }
}
