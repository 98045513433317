import { Component, OnInit,Output,EventEmitter } from '@angular/core';

@Component({
  selector: 'app-confirm-delete-modal',
  templateUrl: './confirm-delete-modal.component.html',
  styleUrls: ['./confirm-delete-modal.component.css']
})
export class ConfirmDeleteModalComponent implements OnInit {
  header: any;
  body: any;
  @Output() confirmDeleteEvent = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
    this.header = 'Delete';
    this.body = 'Delete';
  }
  delete() {
    this.confirmDeleteEvent.emit('');
  }
}
