import { Component, OnInit } from '@angular/core';
import { GroupManagementService } from './group-management.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { changeDateFormat } from "../../shared/utility-functions";
import { FormGroup, FormControl} from '@angular/forms';
import { AppService } from "../../../app.service";

declare var $: any;

@Component({
  selector: 'app-group-management',
  templateUrl: './group-management.component.html',
  styleUrls: ['./group-management.component.css']
})
export class GroupManagementComponent implements OnInit {
  totalEntries = 0;
  fromEntries = 1;
  toEntries =1;
  groups = [];
  users = [];
  filteredUsers = [];
  userList = [];
  oldUserList = [];
  userListName = [];
  selectedGroup: any = {};
  schools = [];
  formdata: any;
  selectAllUsers: boolean = false;
  checkAll: boolean = false;
  newMode: boolean = true;
  modalTitle: string = 'Add Group';
  modalId: string = 'addGroup';
  modules: [];
  topicList = [];
  constructor(
    private groupManagementService: GroupManagementService, 
    private toastr: ToastrManager,
    private appService: AppService
    ) { }

  ngOnInit(): void {
    this.defineForm();
    // this.getAllUsers();
    this.getAllSchools();
    this.getAllModules();
  }

  defineForm(){
    this.formdata = new FormGroup({
      name: new FormControl(""),
      description: new FormControl(""),
      school_id: new FormControl(""),
      userList: new FormControl()
   });
  }

  getAllModules(){
    this.groupManagementService.getAllModules().subscribe((res: any) => {
      this.modules = res.results;
    });
  }

  getAllSchools(){
    this.groupManagementService.getSchools().subscribe((res: any) => {
      this.schools = res.result;
      // this.getAllGroups();
      this.getAllUsers();
 });
  }

  setUsers(schoolId?: any){
    if(!schoolId) return;
    let users = this.users;
    let newUsers = users.filter((user: any) => user.school_id == schoolId)
    this.filteredUsers = newUsers;
  }

  getSchoolName(id: any){
    if(!id) return '';
    let school = this.schools.filter(scl => scl.pk == id);
    if(school){
      return school[0].name;
    }
  }

  getAllGroups(search?: any){
    this.loader('show');
    const DATA = {search: search ? search : ''};
    this.groupManagementService.getAllGroups(DATA).subscribe((res: any) => {
      this.groups = res.result;
      this.totalEntries = res.count;
      this.groups.reverse();
      this.groups.forEach((group: any) => {
        group.school_name = this.getSchoolName(group.school_id);
        group.date = this.changeDateFormat(group.created_at);
      })
      this.loader('hide');
    }, err => {
      this.loader('hide');
    });
  }

  getAllUsers(){
    this.groupManagementService.getAllUser().subscribe((res: any) => {
      this.users = res?.results;
      this.getAllGroups();
    });
  }

  changeDateFormat(date: Date){
    return changeDateFormat(date);
  }

  setSelectedGrp(group: any, newMode?: boolean){
    this.newMode = newMode;
    if(this.newMode){
      this.modalTitle = "Add Group";
      this.modalId = "addGroup"
    } else {
      this.modalTitle = "Edit Group";
      this.modalId = "editGroup"
    }
//-------------for students dropdown ---------------
    this.userList = new Array;
    this.checkAll = false;
    this.selectedGroup = group;
    this.selectedGroup.school_name = this.getSchoolName(group.school_id);
    let userListName = [];
    this.selectedGroup.userList?.forEach((userId: any) => {
      userListName?.push(this.getUserName(userId));
    });
    this.selectedGroup.userListName = userListName;
    this.userList = group.userList;
    this.oldUserList = group.userList;
    this.topicList = group.topicList;

//-------------for module dropdown ---------------
    let topicNames = [];
    this.selectedGroup.topicList?.forEach((id: any) => {
      topicNames?.push(this.getTopicName(id));
    });
    this.selectedGroup.topicNames = topicNames;
}

  getTopicName(id: any){
    if(!id) return '';
    let module : any[];
    module = this.modules.filter((mod: any) => mod.pk == id);
    return module.length > 0 ? module[0].name : '';
  }

  checked(user: any){
    if(this.filteredUsers.length > 0 && this.filteredUsers.length === this.userList.length){
      this.checkAll = true
    } else {
      this.checkAll = false;
    }
    // if(this.newMode){
    //   return this.selectAllUsers;
    // } else {
      if(this.userList.includes(user.pk)){
        return true
      } else {
        return false
      }
    // }
  }

  checkedModule(module: any){
    if(this.topicList.includes(module.pk)){
      return true
    } else {
      return false
    }
  }

  selectAll(){
    this.selectAllUsers = !this.selectAllUsers;
    if(this.selectAllUsers){
      this.userList = this.filteredUsers.map(user => {
        return user.pk
      });  
    } else {
      this.userList = [];
    }
    this.selectedGroup.userListName = this.getUserListName(this.userList);
  }

  clearForm(){
    this.filteredUsers = [];
    this.formReset();
    this.selectedGroup = {};
    this.userList = [];
    this.selectedGroup.userListName = [];
    this.selectAllUsers = false;
    this.modalTitle = "Add Group";
    this.modalId = "addGroup";
    this.newMode = true;
    this.topicList = [];
  }

  selectUsers(data: any){
    let userList = this.userList;
    if(userList.includes(data.pk)){
      userList = userList.filter(user => user !== data.pk);
    } else {
      userList.push(data.pk);
    }
    this.userList = userList;
    this.selectedGroup.userListName = this.getUserListName(this.userList);
    if(this.filteredUsers.length === this.userList.length){
      this.checkAll = true
      this.selectAllUsers = true;
    } else {
      this.selectAllUsers = false;
      this.checkAll = false
    }
  }

  selectModules(module: any){
    let topicList = this.topicList;
    if(topicList.includes(module.pk)){
      topicList = topicList.filter(user => user !== module.pk);
    } else {
      topicList.push(module.pk);
    }
    this.topicList = topicList;
  }

  getUserName(id: any){
    if(!id) return '';
    let user = this.users.filter((usr: any) => usr.pk == id);
    return user.length > 0 ? `${user[0].first_name} ${user[0].last_name}` : '';
  }

  getUserListName(userList: Array<any>){
    let userName = [];
    userList.forEach((userId: any) => {
      userName.push(this.getUserName(userId));
    })
    return userName;
  }

  resetUsers(){
    this.userList = this.oldUserList;
  }

  saveGroup(groupObj: any) {
    groupObj.userList = this.userList;
    groupObj.topicList = this.topicList;
    this.loader('show');
    if(this.newMode){
      this.groupManagementService.addGroup(groupObj).subscribe((res: any) => {
        this.activitiesAfterSave(res.details);
        // this.getAllGroups();
        // this.loader('hide');
        // this.toastr.successToastr(res.details);
        }, err => {
          this.loader('hide');
        });
    } else {
      groupObj.group_id = this.selectedGroup.pk;
      this.groupManagementService.updateGroup(groupObj).subscribe((res: any) => {
        this.activitiesAfterSave(res.details);
        // this.getAllGroups();
        // this.loader('hide');
        // this.toastr.successToastr(res.details);
      }, err => {
        this.loader('hide');
      });
    }
    $(`#${this.modalId}`).modal('hide');
    // this.formdata.reset();
    this.clearForm();
  }

  activitiesAfterSave(resDetails: any){
    this.getAllGroups();
    this.loader('hide');
    this.toastr.successToastr(resDetails);
  }

  formReset(){
    this.formdata.reset();
  }

  disableSclDD(){
    if(this.newMode) return true;
  }

  deleteGroup(){
    this.loader('show');
    this.groupManagementService.deleteGroup(this.selectedGroup.pk).subscribe((res: any) => {
      this.getAllGroups();
      this.loader('hide');
      this.toastr.successToastr(res.status);
    }, err => {
      this.loader('hide');
    });
    $('#deleteGroup').modal('hide');
  }

  searchGroup(searchData: string){
    this.getAllGroups(searchData);
  }
  loader(status: string){
    this.appService.setloaderVisibility(status);
  }
}
