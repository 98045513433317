import { Component, OnInit,Input,Output,EventEmitter, ViewChild } from '@angular/core';
import { QuillModule } from 'ngx-quill';
import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill'

@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.css']
})
export class TextEditorComponent implements OnInit {

  //name = 'ng2-ckeditor';
  // ckeConfig: any;
  @Input() content: string;
  log: string = '';
  @Output() callGetTextArticleDataEvent = new EventEmitter<any>();
 // @ViewChild("myckeditor") ckeditor: any;

  name = 'Angular 6';
  quillEditorRef;
  maxUploadFileSize = 1000000;
  constructor() {
    this.content = '';
  }

  ngOnInit() {
    // this.ckeConfig = {
    //   allowedContent: false,
    //   extraPlugins: 'divarea',
    //   forcePasteAsPlainText: true
    // };
    //let element = document.getElementsByClassName('.ql-editor');
    //let quill = new QuillModule()

  }
  changedEditor(event: EditorChangeContent | EditorChangeSelection) {
    // tslint:disable-next-line:no-console
    
    //console.log('editor-change', event)
    this.content = document.querySelector(".ql-editor").innerHTML;
    this.callGetTextArticleDataEvent.emit(this.content);

  }
  getEditorInstance(editorInstance: any) {
    this.quillEditorRef = editorInstance;
   // console.log(this.quillEditorRef)
    this.quillEditorRef.clipboard.dangerouslyPasteHTML('<p>this is text 22</p>');
    const toolbar = editorInstance.getModule('toolbar');
    toolbar.addHandler('image', this.imageHandler);
  }
  
  imageHandler = (image, callback) => {
    const input = <HTMLInputElement> document.getElementById('fileInputField');
    document.getElementById('fileInputField').onchange = () => {
      let file: File;
      file = input.files[0];
      // file type is only image.
      if (/^image\//.test(file.type)) {
        if (file.size > this.maxUploadFileSize) {
          alert('Image needs to be less than 1MB');
        } else {
          const reader  = new FileReader();
          reader.onload = () =>  {
            const range = this.quillEditorRef.getSelection();
            const img = '<img src="' + reader.result + '" />';
            this.quillEditorRef.clipboard.dangerouslyPasteHTML(range.index, img);
          };
          reader.readAsDataURL(file);
        }
      } else {
          alert('You could only upload images.');
      }
    };

    input.click();
  }
//   onChange($event: any): void {
//     console.log("onChange");
//     this.callGetTextArticleDataEvent.emit(this.content);
//   }
//   Save()
//  {
// 
//  }
//   onPaste($event: any): void {
//     console.log("onPaste");
//   }

}
