import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient) { }

  login(data) { 
    return this.http.post(environment.AUTH_URL + 'api-token-auth', data).pipe(map(res => res));
  }
  forgotPassword(data) {
    return this.http.post(environment.BASE_URL + 'user/forgot-password', data).pipe(map(res => res));
  }
  resetPassword(data) {
    return this.http.post(environment.BASE_URL + 'user/reset-password', data).pipe(map(res => res));
  }
}
