import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { TopicService } from '../../topic/topic.service';
import { VideoService } from '../video.service';
import { environment } from '../../../../../environments/environment';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { NgxSpinnerService } from "ngx-spinner";
import { HttpEventType, HttpClient } from "@angular/common/http";
import { map, catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { secondsToTime } from "../../../shared/utility-functions";


declare var $: any;
@Component({
  selector: 'app-add-video-modal',
  templateUrl: './add-video-modal.component.html',
  styleUrls: ['./add-video-modal.component.css']
})
export class AddVideoModalComponent implements OnInit {
  modal_title: any;
  videoObject: any = {};
  progress: number;
  topicList: any = [];
  totalRecords: any;
  pageNumber: any = 1;
  pageSize: any = 100;
  tempPageObject: any = {};
  selectedTopic: any = {};
  file: any = {};
  articleContent: string;
  isVideoUpload: boolean = false;
  imageUrl: string | ArrayBuffer = "";
  fileName: string = "No file selected";
  fileRemove: boolean = false;
  duration: any = '00:00:00';
  @BlockUI() blockUI: NgBlockUI;
  @Output() callGetAllVideoDataEvent = new EventEmitter<any>();
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: VideoService,
    private topicService: TopicService,
    private toastr: ToastrManager,
    private spinner: NgxSpinnerService,
    private http: HttpClient

  ) {
    this.route.params.subscribe(params => {
      if (params.id) {
        this.getAllTopic().then(v => this.getVideoById(params.id));
        this.duration = params.duration;
      }
    });
  }

 

  ngOnInit(): void {
    this.getAllTopic();
    this.videoObject.is_published = true;
    this.videoObject.url = '';
    this.modal_title = 'Add Watch';
    let dur: any = document.getElementById("duration");
    dur.value = this.duration;
   
  }

  getVideoById(id: any) {
    return new Promise((resolve, reject) => {
      this.service.getVideoByID(id).subscribe((res: any) => {
        if (res) {
          this.videoObject = res;
          if (this.videoObject.image !== '' && this.videoObject.image !== null) {
            this.isVideoUpload = true;
            // this.imageUrl = environment.BASE_URL.replace('/api/', '') + this.videoObject.image;
            this.imageUrl = this.videoObject.image;
          }
          this.selectedTopic = this.topicList.find(i => i.pk === this.videoObject.category_id);
          this.modal_title = 'Edit Watch';
        }
        this.videoObject.duration = new Date(this.videoObject.duration_second * 1000 * 60).toISOString().substr(11, 8)
        delete  this.videoObject.duration_second;

      });
    });
  }
  getAllTopic() {
    return new Promise((resolve, reject) => {
      const data = { 'pageSize': this.pageSize, 'pageIndex': this.pageNumber, 'searchName': '', 'categoryType': 'Watch' };
      this.topicService.getAllTopic(data).subscribe((res: any) => {
        this.topicList = res.results;
        resolve(this.topicList);
        this.totalRecords = res.count;
      }, err => {
      });
    });
  }
  imageArticleList(imgList: any) {
    this.file = imgList;
  }
  onFileChange(file: File) {
    if (file) {
      //File Upload Progress
      this.progress = 1;
      const formData = new FormData();
      formData.append("file", file);
      this.http.post(environment.BASE_URL + 'topic/article/video', formData, {
        reportProgress: true,
        observe: "events"
      })
        .pipe(
          map((event: any) => {
            if (event.type == HttpEventType.UploadProgress) {
              this.progress = Math.round((100 / event.total) * event.loaded);
            } else if (event.type == HttpEventType.Response) {
              this.progress = null;
            }
          }),
          catchError((err: any) => {
            this.progress = null;
            return throwError(err.message);
          })
        )
        .toPromise();
      //File Upload Progress

      this.fileName = file.name;
      this.file[0] = file;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = event => {
        this.imageUrl = reader.result;
        this.isVideoUpload = true;
        this.fileRemove = false;
      };
    }
  }
  removeVideo() {
    this.file = {};
    this.isVideoUpload = false;
    this.imageUrl = '';
    this.fileName = 'No file selected';
    this.fileRemove = true;
  }

  saveVideo() {
   


    try {
      this.videoObject.article_type = 'video';
      this.videoObject.question_type = 'question';
      this.videoObject.user_question = 'False';
      this.videoObject.answer_value = '';
      this.videoObject.explanation = '';
      this.videoObject.explanation_file_id = '';
      this.videoObject.category_id = this.selectedTopic.pk;
      this.videoObject.content = '';
      this.videoObject.difficulty_type = "easy";
    //  this.videoObject.duration = this.duration !== 'null' ? this.duration : '00:00:00';
      if (this.videoObject.pk) {
        this.videoObject.article_id = this.videoObject.pk;
        this.service.updateVideo(this.videoObject).subscribe(data => {
          this.toastr.successToastr('Data updated successfully');
          this.videoUploadingMsg();
          let fileData = { 'file': this.file[0], 'data': data, 'fileRemove': this.fileRemove }
          if (fileData.file || this.fileRemove) {
            this.service.addVideoFile(fileData).subscribe(res => {
              this.toastr.dismissAllToastr();
              this.toastr.successToastr('video updated successfully');
              this.activitiesAfterSave();
            }, err => {
              this.toastr.errorToastr('Unable to save video');
              this.activitiesAfterSave();
            });
          } else {
            this.activitiesAfterSave();
          }
          this.callGetAllVideoDataEvent.emit('');
        }, err => {
          this.toastr.errorToastr('Updation failed, try again');
        
          this.router.navigate(['video']);
        });
      } else {
        this.service.createVideo(this.videoObject).subscribe(data => {
          this.toastr.successToastr('Data Added successfully');
          this.videoUploadingMsg();
          this.videoObject = data;
          let fileData = { 'file': this.file[0], 'data': data, 'fileRemove': this.fileRemove }
          if (fileData.file) {
            this.service.addVideoFile(fileData).subscribe(res => {
              this.toastr.dismissAllToastr();
              this.toastr.successToastr('video added successfully');
              this.activitiesAfterSave();
            }, err => {
              this.toastr.errorToastr('Unable to save video');
              this.activitiesAfterSave();
            });
          } else {
            this.activitiesAfterSave();
          }
          this.activitiesAfterSave();
          this.callGetAllVideoDataEvent.emit('');
        }, err => {
          this.toastr.errorToastr('Unable to save');
          this.activitiesAfterSave();
        });
      }
    } catch (ex) {
      this.toastr.errorToastr('Please enter valid data');
    }
  }

  videoUploadingMsg() {
    setTimeout(() => {
      this.toastr.dismissAllToastr();
      this.toastr.infoToastr('Video is uploading...');
    }, 2000);
  }

  activitiesAfterSave() {
    this.router.navigate(['video']);
  }

  getVideo(event: any) {
    let duration: any = Math.floor(event.target.duration);
    if(duration == Infinity) {
      this.duration = '00:00:00';
    } else {
      let time = secondsToTime(duration);
      this.duration = `${time.h}:${time.m}:${time.s}`;
      let dur: any = document.getElementById("duration");
      dur.value = this.duration;
    }
  }
}
