import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// Import Containers
import { DefaultLayoutComponent } from './containers';
import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { ForgotPasswordComponent } from './views/login/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './views/login/reset-password/reset-password.component';
import { RegisterComponent } from './views/register/register.component';
import { UserComponent } from './views/admin/user/user.component';
import { DashboardComponent } from './views/admin/dashboard/dashboard.component';
import { ProfileComponent } from './views/admin/profile/profile.component';
import { AuthGuard } from './views/auth/auth.guard';
import { TopicComponent } from './views/admin/topic/topic.component';
import { VideoComponent } from './views/admin/video/video.component';
import { ArticleComponent } from './views/admin/article/article.component';
import { HomeLayoutComponent } from './containers/layout/home-layout/home-layout.component'
import { AddArticleModalComponent } from './views/admin/article/add-article-modal/add-article-modal.component';
import { AddVideoModalComponent } from './views/admin/video/add-video-modal/add-video-modal.component';
import { NotificationComponent } from './views/notification/notification.component';
import { UserQuestionsComponent } from './views/admin/user-questions/user-questions.component';
import { TechnicalsupportComponent } from './views/admin/technicalsupport/technicalsupport.component';
import { QuizManagerComponent } from './views/admin/quiz-manager/quiz-manager.component';
import { AddQuizMangerComponent } from './views/admin/quiz-manager/add-quiz-manger/add-quiz-manger.component';
import { GroupManagementComponent } from "./views/admin/group-management/group-management.component";
import { BadgesComponent } from './views/admin/badges/badges.component';
import { LeaderboardComponent } from "./views/admin/leaderboard/leaderboard.component";
import { ResourceComponent } from "./views/admin/resource/resource.component";
import { PointsManagementComponent } from "./views/admin/points-management/points-management.component";
import { PledgeComponent } from "./views/admin/pledges/pledge.component";
import { AddPledgeModalComponent } from "./views/admin/pledges/add-pledge-modal/add-pledge-modal.component";
import { ContentComponent } from "./views/admin/content/content.component";
import { ErrorComponent } from "./views/admin/error/error.component";

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    data: {
      title: 'Forgot password Page'
    }
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    data: {
      title: 'Reset password Page'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,canActivate: [AuthGuard],
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
        data: {
          title: 'dashboard Page'
        }
      },
      {
        path: 'topic',
        component: TopicComponent,
        data: {
          title: 'topic Page'
        }
      },
      {
        path: 'article',
        component: ArticleComponent,
        data: {
          title: 'article Page'
        }
      },
      {
        path: 'add-article',
        component: AddArticleModalComponent,
        data: {
          title: 'add article Page'
        }
      },
      {
        path: 'add-article/:id',
        component: AddArticleModalComponent,
        data: {
          title: 'add article Page'
        }
      },
      {
        path: 'video',
        component: VideoComponent,
        data: {
          title: 'video Page'
        }
      },
      {
        path: 'add-video',
        component: AddVideoModalComponent,
        data: {
          title: 'add video Page'
        }
      },
      {
        path: 'quiz-manager',
        component: QuizManagerComponent,
        data: {
          title: 'quiz-manager Page'
        }
      },
      {
        path: 'add-quiz',
        component: AddQuizMangerComponent,
        data: {
          title: 'add quiz Page'
        }
      },
      {
        path: 'pledges',
        component: PledgeComponent,
        data: {
          title: 'Pleges Page'
        }
      },
      {
        path: 'add-pledge',
        component: AddPledgeModalComponent,
        data: {
          title: 'add pledge Page'
        }
      },
      {
        path: 'user-questions',
        component: UserQuestionsComponent,
        data: {
          title: 'user-questions Page'
        }
      },
      {
        path: 'user',
        component: UserComponent,
        data: {
          title: 'user Page'
        }
      },
      {
        path: 'profile',
        component: ProfileComponent,
        data: {
          title: 'profile page'
        }
      },
      {
        path: 'notification',
        component: NotificationComponent,
        data: {
          title: 'notification page'
        }
      },
      {
        path: 'groups',
        component: GroupManagementComponent,
        data: {
          title: 'group'
        }
      },
      {
        path: 'badges',
        component: BadgesComponent,
        data: {
          title: 'Badges'
        }
      },
      {
        path: 'technicalsupport',
        component: TechnicalsupportComponent,
        data: {
          title: 'technicalsupport page'
        }
      },
      {
        path: 'leaderboard',
        component: LeaderboardComponent,
        data: {
          title: 'leaderboard page'
        }
      },
      {
        path: 'resource',
        component: ResourceComponent,
        data: {
          title: 'resource page'
        }
      },
      {
        path: 'points',
        component: PointsManagementComponent,
        data: {
          title: 'points page'
        }
      },
      {
        path: 'content',
        component: ContentComponent,
        data: {
          title: 'content'
        }
      },
      {
        path: 'error',
        component: ErrorComponent, 
        data: {
          title: 'error'
        }
      },

    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
