import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router} from '@angular/router';
import {ActivatedRoute} from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { PledgeService } from '../pledge.service';
import { TopicService } from '../../topic/topic.service';
import { FormGroup, FormControl} from '@angular/forms';

declare var $: any;
@Component({
  selector: 'app-add-pledge-modal',
  templateUrl: './add-pledge-modal.component.html',
  styleUrls: ['./add-pledge-modal.component.css']
})
export class AddPledgeModalComponent implements OnInit {
  modal_title: any;
  articleObject: any = {};
  topicList: any = [];
  totalRecords: any;
  pageNumber: any = 1;
  pageSize: any = 100;
  tempPageObject: any = {};
  selectedTopic: any = {};
  quillEditorRef: any;
  maxUploadFileSize = 1000000;
  formdata: any;
  @Output() callGetAllArticleDataEvent = new EventEmitter<any>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: PledgeService,
    private topicService: TopicService, 
    private toastr: ToastrManager
    ) {
      this.route.params.subscribe(params => {
        if(params.id){
         this.getAllTopic().then( a => this.getArticleById(params.id));          
        }
      });
    }

  ngOnInit(): void {
    this.defineForm();
    this.getAllTopic();
    this.articleObject.is_published =true;
    this.modal_title = 'Add Pledge';
  }

  defineForm(){
    this.formdata = new FormGroup({
      title: new FormControl(""),
      testimonial: new FormControl(""),
      pledge_type: new FormControl(""),
      category_id: new FormControl("")
   });
  }

  getArticleById(id: string){
    this.service.getArticleByID(id).subscribe((res: any) => {
      if(res){
        this.articleObject = res;
        if(this.articleObject){
          this.modal_title = 'Edit Pledge';
        }
      }
    });   
  }

  getArticleFile(id){
    this.service.getArticleFile(id).subscribe((res:any) =>{
      if(res){}
    });
  }

  addArticle() {
    this.articleObject = {};
    this.articleObject.is_published = true;
    this.modal_title = 'Add Pledge';
  }

  editArticle(data) {
    this.articleObject = data;
    this.modal_title = 'Update Pledge';
  }

  getAllTopic() {
    return new Promise((resolve, reject) => {
      const data = { 'pageSize': this.pageSize, 'pageIndex': this.pageNumber, 'searchName': '', 'categoryType': 'Learn' };
      this.topicService.getAllTopic(data).subscribe((res: any) => {
        this.topicList = res.results;
        resolve(this.topicList);
        this.topicList.forEach(element => {
          if (element.is_published === true) {
            element.status = 'Yes';
          } else {
            element.status = 'No';
          }
        });
        this.totalRecords = res.count;
      }, err => {});
    });
  }

  saveArticle() {
    try {
      this.articleObject.article_type = 'pledge';
      if (this.articleObject.pk) {
        this.articleObject.article_id = this.articleObject.pk;
        this.service.updateArticle(this.articleObject).subscribe(data => {      
          this.supplimentaryActivities('Updated');
        }, err => {});
      } else {
        this.service.createArticle(this.articleObject).subscribe(data => {   
          this.supplimentaryActivities('Added');
        }, err => {
        });
      }
    } catch (err) {
      this.toastr.errorToastr('Please enter valid data');
    }
  }

  supplimentaryActivities(toasterType: string){
    this.router.navigate(['pledges']);
    this.toastr.successToastr(`Pledge ${toasterType} successfully`);
    this.callGetAllArticleDataEvent.emit('');
  }
}