import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { QuizManagerService } from '../quiz-manager.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { TopicService } from '../../topic/topic.service';
import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill'
declare var $: any;
enum CheckBoxType { A, B, C, D };
@Component({
  selector: 'app-add-question-bank-modal',
  templateUrl: './add-question-bank-modal.component.html',
  styleUrls: ['./add-question-bank-modal.component.css']
})
export class AddQuestionBankModalComponent implements OnInit {
  modal_title: any;
  topicList: any;
  QuestionObject: any = {};
  selectedTopic: any = {};
  selectedLearn: any = 'Select Learn Article';
  selectedWatch: any = 'Select Watch Article';
  editQuizMode: boolean;
  quillEditorRef;
  questionQuillEditorRef;
  editorTextContent: any;
  maxUploadFileSize = 1000000;
  optionList: any = [];
  check_box_type = CheckBoxType;
  currentlyChecked: CheckBoxType;
  optionValue_A: any;
  optionValue_B: any;
  optionValue_C: any;
  optionValue_D: any;
  optionValue_A_Checked:boolean = false;
  optionValue_B_Checked:boolean = false;
  optionValue_C_Checked:boolean = false;
  optionValue_D_Checked:boolean = false;
  isShowOptions:boolean =false;
  isExplaniation:boolean =false;
  quizTypeList: string[] = ["Easy", "Medium", "Hard"];
  selectedQuizType = "";
  question = '';
  explanation = '';
  allLearnTopics: any = [];
  allWatchTopics: any = [];
  learnTopics: any = [];
  watchTopics: any = [];
  @Output() callGetAllQuestionDataEvent = new EventEmitter<any>();
  @Output() callGetSingleQuestionDataEvent = new EventEmitter<any>();

  constructor(private service: QuizManagerService, private toastr: ToastrManager,
    private topicService: TopicService) { }

  ngOnInit(): void {
   this.getAllModules();
    this.getLearnTopics();
   this.getWatchTopics();
  }

  getLearnTopics(){
    this.service.getLearnTopics().subscribe((res: any) => {
      this.allLearnTopics = res.results;
      console.log('allLearnTopics',this.allLearnTopics);

    })
  }

  getWatchTopics(){
    this.service.getWatchTopics().subscribe((res: any) => {
      this.allWatchTopics = res.results;
    })
  }

  filterTopics(moduleId: any){
    this.learnTopics = this.allLearnTopics.filter((topic: any) => topic.category_id == moduleId);
   // this.getLearnTopics(moduleId);
    this.watchTopics = this.allWatchTopics.filter((topic: any) => topic.category_id == moduleId);

  }
  
  selectCheckBox(targetType: CheckBoxType) {   
    this.currentlyChecked = targetType;
    this.optionValue_A_Checked =false;
    this.optionValue_B_Checked =false;
    this.optionValue_C_Checked =false;
    this.optionValue_D_Checked =false;
    if(this.currentlyChecked == 0){
      this.optionValue_A_Checked =true;
    }else if( this.currentlyChecked == 1){
      this.optionValue_B_Checked =true;
    }else if(this.currentlyChecked == 2){
      this.optionValue_C_Checked =true;
    }else if(this.currentlyChecked == 3){
      this.optionValue_D_Checked =true;
    }
  }
  getAllModules() {
    
    let data = {};
    data = { 'pageSize': 1000, 'pageIndex': 1, 'categoryType': '', 'searchName': '' };
    this.topicService.getAllTopic(data).subscribe((res: any) => {
      
      this.topicList = res.results;
      this.topicList.forEach(element => {
        if (element.is_published === true) {
          element.status = 'Published';
        } else {
          element.status = 'Draft';
        }
      });
    }, err => {

    });
  }
  addQuestionBank(editQuizMode?: boolean, module?: {id?: any, name?: string}) {
    this.modal_title = 'Add Question';
    this.selectedTopic = module?.id;
    this.filterTopics(module?.id);
    this.editQuizMode = editQuizMode;
    this.QuestionObject = {};
    this.question = '';
    this.selectedQuizType = '';
    this.currentlyChecked = 100;
    this.explanation = '';
    this.optionValue_A_Checked =false;
    this.optionValue_B_Checked =false;
    this.optionValue_C_Checked =false;
    this.optionValue_D_Checked =false;
    this.optionValue_A = '';
    this.optionValue_B = '';
    this.optionValue_C = '';
    this.optionValue_D = '';
    this.isExplaniation = false;
  }
  editQuestionBank(data) {
    this.isExplaniation =false;
    this.modal_title = 'Edit Question';
    this.QuestionObject = data;
    this.question = this.QuestionObject.title;
    this.explanation = this.QuestionObject.explanation
    this.selectedQuizType = this.QuestionObject.difficulty_type;
    let readId = this.QuestionObject.related_read_id;
    let watchId = this.QuestionObject.related_watch_id;
    this.selectedLearn = readId ? readId : 'Select Learn Article';
    this.selectedWatch = watchId ? watchId : 'Select Watch Article';
    if(this.QuestionObject.explanation !== null && this.QuestionObject.explanation !==''){
      this.isExplaniation =true;
      this.editorTextContent = this.QuestionObject.explanation;
      if( this.questionQuillEditorRef?.clipboard){
        this.questionQuillEditorRef.clipboard.dangerouslyPasteHTML(this.editorTextContent);
      }
    }
    this.selectedTopic = this.QuestionObject.category_id;
    this.filterTopics(this.QuestionObject.category_id);
    if(this.QuestionObject.options.length > 0){
      this.QuestionObject.options.forEach(ele => {
        if(ele.option_key === 'A'){
          this.optionValue_A = ele.option_value;
          this.optionValue_A_Checked =ele.is_correct_choice;
          if(ele.is_correct_choice){
            this.selectCheckBox(0);
          }  
        } else if(ele.option_key === 'B'){
          this.optionValue_B = ele.option_value;
          this.optionValue_B_Checked =ele.is_correct_choice;
          if(ele.is_correct_choice){
            this.selectCheckBox(1);
          } 
        } else if(ele.option_key === 'C'){
          this.optionValue_C = ele.option_value;
          this.optionValue_C_Checked =ele.is_correct_choice;
          if(ele.is_correct_choice){
            this.selectCheckBox(2);
          } 
        } else if(ele.option_key === 'D'){
          this.optionValue_D = ele.option_value;
          this.optionValue_D_Checked =ele.is_correct_choice
          if(ele.is_correct_choice){
            this.selectCheckBox(3);
          } 
        } 
      });
    }
  }
  viewQuestionBank(data){
    this.QuestionObject = data;
    if(this.QuestionObject.options.length > 0){
      this.isShowOptions =true;
      this.QuestionObject.options.forEach(ele => {
        if(ele.option_key === 'A'){
          this.optionValue_A = ele.option_value;
          this.optionValue_A_Checked =ele.is_correct_choice
        } else if(ele.option_key === 'B'){
          this.optionValue_B = ele.option_value;
          this.optionValue_B_Checked =ele.is_correct_choice
        } else if(ele.option_key === 'C'){
          this.optionValue_C = ele.option_value;
          this.optionValue_C_Checked =ele.is_correct_choice
        } else if(ele.option_key === 'D'){
          this.optionValue_D = ele.option_value;
          this.optionValue_D_Checked =ele.is_correct_choice
        } 
      });
    }else{
      this.isShowOptions =false;
    }
  }

  saveQuestion() {

    if(!this.question) {
      this.toastr.warningToastr('Please fill your Question');
      return;
    }

    if(!this.selectedTopic) {
      this.toastr.warningToastr('Please select module');
      return;
    }
    

    if(!this.selectedLearn || this.selectedLearn == "Select Learn Article") {
      this.toastr.warningToastr('Please select learn article');
      return;
    }

    if(!this.selectedQuizType) {
      this.toastr.warningToastr('Please select difficulty type');
      return;
    }

    let answers = 0;

    answers = this.optionValue_A ? answers + 1 : answers;
    answers = this.optionValue_B ? answers + 1 : answers;
    answers = this.optionValue_C ? answers + 1 : answers;
    answers = this.optionValue_D ? answers + 1 : answers;

    if (answers < 2){
      this.toastr.warningToastr('Please fill atleast two answer choices');
      return;
    }

    if (!(this.optionValue_A_Checked || this.optionValue_B_Checked || this.optionValue_C_Checked || this.optionValue_D_Checked)){
      this.toastr.warningToastr('Please select correct answer');
      return;
    }

    try {
      this.optionList = [
        {
          "key":"A",
          "value" : this.optionValue_A,
          "is_correct_choice" :this.optionValue_A_Checked
      },
         {
          "key":"B",
          "value" : this.optionValue_B,
          "is_correct_choice" :this.optionValue_B_Checked
      },
         {
          "key":"C",
          "value" : this.optionValue_C,
          "is_correct_choice" :this.optionValue_C_Checked
      },
          {
          "key":"D",
          "value" : this.optionValue_D,
          "is_correct_choice" :this.optionValue_D_Checked
      }
      ]
      this.QuestionObject.question = this.question;
      this.QuestionObject.explanation = this.explanation;
      this.QuestionObject.type = 'checkbox';
      this.QuestionObject.article_type = 'question';
      this.QuestionObject.options = this.optionList;
      this.QuestionObject.difficulty_type = this.selectedQuizType;
      this.QuestionObject.category_id = Number(this.selectedTopic);
      this.QuestionObject.topic_id = this.selectedTopic;
      this.QuestionObject.related_read_id = this.selectedLearn;
      this.QuestionObject.related_watch_id = this.selectedWatch;
      if (this.QuestionObject.pk) {
        this.QuestionObject.article_id = this.QuestionObject.pk;
        this.service.updateQuestionBank(this.QuestionObject).subscribe(data => {
          $('#addQuestionBank').modal('hide');
          this.toastr.successToastr('Question updated successfully');
          this.callGetAllQuestionDataEvent.emit('');
          this.callGetSingleQuestionDataEvent.emit(data);
          this.clearForm();
        }, err => {

        });

      } else {
        this.service.addQuestionBank(this.QuestionObject).subscribe((data: any) => {
          $('#addQuestionBank').modal('hide');
          this.toastr.successToastr('Question added successfully');
          if(!this.editQuizMode && (this.selectedTopic || this.selectedTopic == 0)){
            this.updateQuiz(this.selectedTopic, data.article_id);
          }
          let newQuesObj = this.getNewQuesObj(data);
          this.callGetAllQuestionDataEvent.emit('');
          this.callGetSingleQuestionDataEvent.emit(newQuesObj);
          this.clearForm();
        }, err => {
        });
      }
    } catch (ex) {
      this.toastr.errorToastr('Please enter valid data');
    }
  }

  updateQuiz(selectedTopic: any, article_id: any){
    let quiz: any = {};
    let data = { 'pageSize': 100, 'pageIndex': 1 };
    this.service.getAllQuiz(data).subscribe((res: any) => {
      let arr = res.result;
      quiz = arr.find((qz:any) => qz.quiz_id == selectedTopic);
      quiz.category_id = selectedTopic;
      quiz.questionList = quiz.questions?.map((qst: any) => qst.pk);
      quiz.questionList.push(article_id);
      quiz.question_count = quiz.questionList?.length;
      this.service.updateQuiz(quiz).subscribe((quizRes: any) => {
        // Question added to quiz.
      })
    })
  }

  getNewQuesObj(data: any){
    let newQuesObj = this.QuestionObject;
    newQuesObj.pk = data.article_id;
    newQuesObj.article_id = data.article_id;
    newQuesObj.created_at = data.created_at;
    newQuesObj.updated_at = data.created_at;
    newQuesObj.created_by = data.created_by;
    newQuesObj.title = data.title;
    return newQuesObj;
  }

  clearForm(){
    this.question = '';
    this.selectedQuizType = '';
    this.selectedTopic = '';
    this.currentlyChecked = 9;
    this.explanation = '';
  }

  imageHandler = (image, callback) => {
    const input = <HTMLInputElement>document.getElementById('fileInputField');
    document.getElementById('fileInputField').onchange = () => {
      let file: File;
      file = input.files[0];
      if (/^image\//.test(file.type)) {
        if (file.size > this.maxUploadFileSize) {
          alert('Image needs to be less than 1MB');
        } else {
          const reader = new FileReader();
          reader.onload = () => {

            const range = this.quillEditorRef.getSelection();
            const img = '<img src="' + reader.result + '" width="70" height="70"/>';
            this.quillEditorRef.clipboard.dangerouslyPasteHTML(range.index, img);
          };
          reader.readAsDataURL(file);
        }
      } else {
        alert('You could only upload images.');
      }
    };

    input.click();
  }

  getquestionEditorInstance(editorInstance: any) {
    
    this.questionQuillEditorRef = editorInstance;
    const toolbar = editorInstance.getModule('toolbar');
    toolbar.addHandler('image', this.questionImageHandler);
  }
  changedQuestionEditor(event: EditorChangeContent | EditorChangeSelection) {
    
    console.log('editor-change', event)
   this.editorTextContent = event.editor.root.innerHTML;
  }
  questionImageHandler = (image, callback) => {
    const input = <HTMLInputElement>document.getElementById('questionfileInputField');
    document.getElementById('questionfileInputField').onchange = () => {
      let file: File;
      file = input.files[0];
      if (/^image\//.test(file.type)) {
        if (file.size > this.maxUploadFileSize) {
          alert('Image needs to be less than 1MB');
        } else {
          const reader = new FileReader();
          reader.onload = () => {

            const range = this.questionQuillEditorRef.getSelection();
            const img = '<img src="' + reader.result + '" width="70" height="70"/>';
            this.questionQuillEditorRef.clipboard.dangerouslyPasteHTML(range.index, img);
          };
          reader.readAsDataURL(file);
        }
      } else {
        alert('You could only upload images.');
      }
    };

    input.click();
  }

  getLearnTopicName(id: any){
    let article = this.allLearnTopics.find((topic: any) => topic.pk == id);
    return article?.title ? article?.title : '-';
  }
  getWatchTopicName(id: any){
    let article = this.allWatchTopics.find((topic: any) => topic.pk == id);
    return article?.title ? article?.title : '-';
  }
}
