import { Component, OnInit } from '@angular/core';
import { PointsManagementService } from './points-management.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { POINTS } from "../../shared/utility-functions";
import { AppService } from "../../../app.service";
declare var $: any;

@Component({
  selector: 'app-points-management',
  templateUrl: './points-management.component.html',
  // styleUrls: ['./points-management.component.css']
})
export class PointsManagementComponent implements OnInit {
  allPoints = [];
  selectedPoint: any = {};

  constructor(
    private pointsManagementService: PointsManagementService, 
    private toastr: ToastrManager,
    private appService: AppService
    ) { }

  ngOnInit(): void {
    this.getAllPoints();
  }

  getPointName(activity: string){
    let act = activity?.trim();
    let val = POINTS[act]
    return val ? val : activity;
  }

  getAllPoints(){
    this.loader('show');
    this.pointsManagementService.getAllPoints().subscribe((res: any) => {
      this.loader('hide');
      this.allPoints = res;
    }, err => {
      this.loader('hide');
    });
  }

  updatePoint(point_earned: any){
    if(!this.selectedPoint?.pk) return;
    let data = {
      "pk": this.selectedPoint?.pk?.toString(),
      "point_earned": point_earned
    };
    this.pointsManagementService.updatePoint(data).subscribe((res: any) => {
      this.getAllPoints();
      this.toastr.successToastr("Point updated successfully");
    });
    $('#editPoint').modal('hide');
  }

  setSelectedPoint(point: any){
    this.selectedPoint = point;
  }
  loader(status: string) {
    this.appService.setloaderVisibility(status);
  }
}
