import { Component, OnInit } from '@angular/core';
import { TechnicalSupportService } from './technicalsupport.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { replaceAll } from "../../shared/utility-functions";
import { Subject } from 'rxjs';
import { AppService } from "../../../app.service";

declare var $: any;

@Component({
  selector: 'app-technicalsupport',
  templateUrl: './technicalsupport.component.html',
  styleUrls: ['./technicalsupport.component.css']
})
export class TechnicalsupportComponent implements OnInit {
  technicalsupportList: any;
  searchFeedbackObject: any = {};
  totalRecords: any;
  pageNumber: any = 1;
  customInput: Subject<string> = new Subject();
  pageSize: any = 30;
  tempPageObject: any = {};
  pageSizeList: string[] = ["30", "50","70"];
  selectedPageSize = "30";
  config: any;
  Status: any = '';
  selectedIssue: any = {};
  issues: any = [];
  feedback: any = [];
  problemType: string = '';
  feedbacks: any = [];
  selectedFeed: any = {};

  constructor(
    private service: TechnicalSupportService, 
    private toastr: ToastrManager,
    private appService: AppService
  ) { }

  ngOnInit(): void {
    this.getIssues();
    this.getFeedbacks();
    this.config = {
      itemsPerPage: this.selectedPageSize,
      currentPage: this.pageNumber,
      totalItems: this.totalRecords
    };
  }

  getIssues(problem_type?: string, search?: string){
    this.loader('show');
    
    this.problemType = problem_type;
    let data = {
      'problem_type': problem_type ? problem_type : "",
      'pageSize': this.pageSize, 
      'pageIndex': this.pageNumber,
      'search': search ? search : ''
    }
    this.service.getIssues(data).subscribe((res: any) => {
      var length = 63;
      this.issues = res.result;
      this.issues.forEach((issue:any) => {
        issue.ticketName = this.getTicketName(issue);
        if (issue.ticketName != null){
          var updatedTicketName = issue.ticketName.length > length ? issue.ticketName.substring(0, length - 3) + "..." : issue.ticketName;
          issue.updatedTicketName = updatedTicketName;
        }
      });
      this.totalRecords = res.count;
        this.config = {
          itemsPerPage: this.selectedPageSize,
          currentPage: this.pageNumber,
          totalItems: this.totalRecords
        };
      this.loader('hide');
    })
  }

  getFeedbacks(search?: string){
    let data = {
      'pageSize': this.pageSize, 
      'pageIndex': this.pageNumber,
      'search': search ? search : '',
    }
    this.service.getFeedback(data).subscribe((res: any) => {
      this.feedbacks = res.results;
    })
  }

  updateStatus(issue: any){
    issue.status = !issue.status;
    this.service.updateIssueStatus(issue).subscribe((res: any) => {
      this.getIssues(this.problemType);
      this.toastr.successToastr("Status updated successfully");
      this.totalRecords = res.count;
        this.config = {
          itemsPerPage: this.selectedPageSize,
          currentPage: this.pageNumber,
          totalItems: this.totalRecords
        };
      
    });
  }

  deleteFeedback(){
    this.service.deleteFeedback(this.selectedFeed.pk).subscribe((res: any) => {
      this.getFeedbacks();
      this.toastr.successToastr("Feedback deleted successfully");
    });
    $(`#deleteFeedback`).modal('hide');
  }

  getTicketName(issue: any){
    if(issue.problem_type == "violation"){
      let name = this.getCorrectName(issue.problem_list)
      return `${name} - ${issue.description}`;
    } else {
      return issue.ticket;
    }
  }

  getCorrectName(name: any){
    let nName = replaceAll(name, "null,", "");
    nName = replaceAll(nName, ",null", "");
    return nName;
  }

  formatDate(date: any){
    if(!date) return;
    let dt = new Date(date);
    return `${dt.getDate()}/${dt.getMonth() + 1}/${dt.getFullYear()} ${dt.getHours()}:${dt.getMinutes()}`
  }

  selectIssue(issue: any) {
    this.selectedIssue = issue;    
  }
  
  searchTechnicalSupport(search: any) {
    this.getIssues(this.problemType, search);
  }

  searchFeedback(search) {
    this.getFeedbacks(search);
  }
  pageChanged(event){
    this.pageNumber = event;
    this.getIssues(this.problemType);
  }

  onPageSizeChange(event){
    this.pageSize = event;
    this.pageNumber =1;
    this.getIssues(this.problemType);
  }

  selectFeedback(feed: any){
    this.selectedFeed = feed;
  }

  loader(status: string) {
    this.appService.setloaderVisibility(status);
  }
}
