import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  getAllUser(data) {
    return this.http.get(environment.BASE_URL + 'user/?page_size='+data.pageSize +'&page='+ data.pageIndex + '&search_user=' + data.searchUser + '&school_id=' + data.school_id + '&is_staff='+ data.is_staff + '').pipe(map(res => res));
  }
  addUser(data) {
    return this.http.post(environment.BASE_URL + 'user/create', data).pipe(map(res => res));
  }
  updateUser(data) {
    return this.http.post(environment.BASE_URL + 'user/update', data).pipe(map(res => res));
  }
  deleteUser(userId) {
    return this.http.delete(environment.BASE_URL + 'user/delete/' + userId+'/').pipe(map(res => res));
  }

  getAllSchool() {
    return this.http.get(environment.BASE_URL + 'user/schools').pipe(map(res => res));
  }

  addSchool(data) {
    return this.http.post(environment.BASE_URL + 'user/schools', data).pipe(map(res => res));
  }

  updateSchool(data) {
    return this.http.post(environment.BASE_URL + 'user/schools', data).pipe(map(res => res));
  }
  
  deleteSchool(schoolId) {
    return this.http.delete(environment.BASE_URL + 'user/schools?id=' + schoolId).pipe(map(res => res));
  }

  userProgress(userId) {
    return this.http.get(environment.BASE_URL + 'user/progress?user_id=' + userId).pipe(map(res => res));
  }

  // importfile(data) {
  //   return this.http.post(environment.BASE_URL + 'user/import' ,data).pipe(map(res => res));
  // }

  importfile(fileData: any){
    let file: File = fileData.file;
    let formData: FormData = new FormData();
    //console.log("fileData",fileData);
    if(file){
      formData.append('file', file, file.name); 
    }
      formData.append('url',fileData.data && fileData.data.url?fileData.data.url:'');
      formData.append('fileRemove', fileData.fileRemove);
      let headers = new HttpHeaders();
      headers.append('Content-Type', 'multipart/form-data');
      headers.append('Accept', 'application/json');
      let options = { headers: headers };
     // console.log("formData",formData);

      return this.http.post(environment.BASE_URL + 'user/import', formData, options).pipe(map(res => res));
  }

}
