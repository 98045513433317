import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { UserQuestionsService } from '../user-questions.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { TopicService } from '../../topic/topic.service';
import { AppService } from "../../../../app.service";
// import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill'
declare var $: any;
@Component({
  selector: 'app-add-faq-modal',
  templateUrl: './add-faq-modal.component.html',
  styleUrls: ['./add-faq-modal.component.css']
})
export class AddFaqModalComponent implements OnInit {
  modal_title: any;
  topicList: any;
  userQuestionObject: any = {};
  question:any;
  selectedTopic: any = {};
  quillEditorRef;
  maxUploadFileSize = 1000000;
  faqEditorTextContent: any;
  answer: string;
  @Output() callGetAllUserQuestionDataEvent = new EventEmitter<any>();

  constructor(
    private service: UserQuestionsService, 
    private toastr: ToastrManager,
    private topicService: TopicService,
    private appService: AppService) { }

  ngOnInit(): void {
    this.getAllModules();
  }

  getAllModules() {
    
    let data = {};
    data = { 'pageSize': 1000, 'pageIndex': 1, 'categoryType': '', 'searchName': '' };
    this.topicService.getAllTopic(data).subscribe((res: any) => {
      
      this.topicList = res.results;
      this.topicList.forEach(element => {
        if (element.is_published === true) {
          element.status = 'Published';
        } else {
          element.status = 'Draft';
        }
      });
    }, err => {

    });
  }
  addFaqs() {
    this.modal_title = 'Add FAQs';
    this.userQuestionObject = {};
    this.question = '';
    this.answer = '';
    this.selectedTopic = {};
    this.faqEditorTextContent = "";
    if( this.quillEditorRef.clipboard){
      this.quillEditorRef.clipboard.dangerouslyPasteHTML(this.faqEditorTextContent);
    }
  }
  editFaqs(data) {
    this.modal_title = 'Edit FAQs';
    this.userQuestionObject = data;
    // this.faqEditorTextContent = "";
    // this.faqEditorTextContent = this.userQuestionObject.answer;
    this.question = this.userQuestionObject.question;
    this.answer = this.userQuestionObject.answer;
    // if (this.userQuestionObject.answer === null) {
    //   this.faqEditorTextContent = "";
    // }
    // if( this.quillEditorRef.clipboard){
    //   this.quillEditorRef.clipboard.dangerouslyPasteHTML(this.faqEditorTextContent);
    // }
    this.selectedTopic = this.topicList.find(i => i.pk === data.category);
  }

  saveFaqQuestion() {
    try {
      if (!this.question) {
        return this.toastr.infoToastr('Please enter question');
      }
      if (this.selectedTopic.pk) {
        this.userQuestionObject.category_id = this.selectedTopic.pk;
        this.userQuestionObject.category_name =  this.selectedTopic.name;
      } else {
        return this.toastr.infoToastr('Please select module');
      }
      this.userQuestionObject.answer = this.answer;
      this.userQuestionObject.question = this.question;
      this.loader('show');
      if (this.userQuestionObject.pk) {
        this.userQuestionObject.question_id = this.userQuestionObject.pk;
        this.service.updateUserQuestion(this.userQuestionObject).subscribe(data => {
          $('#addFaqs').modal('hide');
          this.toastr.successToastr('FAQ updated successfully');
          this.callGetAllUserQuestionDataEvent.emit('');
          this.loader('hide');
        }, err => {
          this.loader('hide');
        });
      } else {
        this.service.addUserQuestion(this.userQuestionObject).subscribe(data => {
          $('#addFaqs').modal('hide');
          this.toastr.successToastr('FAQ added successfully');
          this.callGetAllUserQuestionDataEvent.emit('');
          this.loader('hide');
        }, err => {
          this.loader('hide');
          // this.blockedUI(false);
        });
      }
    } catch (ex) {
      this.toastr.errorToastr('Please enter valid data');
    }
  }

  // changedEditor(event: EditorChangeContent | EditorChangeSelection) {
    
  //   console.log('editor-change', event)
  //   //this.faqEditorTextContent = document.querySelector("#faqeditor.ql-editor").innerHTML;
  //   this.faqEditorTextContent = event.editor.root.innerHTML;
  //   //this.callGetTextEditorDataEvent.emit(this.content);
  // }
  // getEditorInstance(editorInstance: any) {
    
  //   this.quillEditorRef = editorInstance;
  //   const toolbar = editorInstance.getModule('toolbar');
  //   toolbar.addHandler('image', this.imageHandler);
  // }
  // imageHandler = (image, callback) => {
  //   const input = <HTMLInputElement>document.getElementById('fileInputField');
  //   document.getElementById('fileInputField').onchange = () => {
  //     let file: File;
  //     file = input.files[0];
  //     if (/^image\//.test(file.type)) {
  //       if (file.size > this.maxUploadFileSize) {
  //         alert('Image needs to be less than 1MB');
  //       } else {
  //         const reader = new FileReader();
  //         reader.onload = () => {

  //           const range = this.quillEditorRef.getSelection();
  //           const img = '<img src="' + reader.result + '" width="70" height="70"/>';
  //           this.quillEditorRef.clipboard.dangerouslyPasteHTML(range.index, img);
  //         };
  //         reader.readAsDataURL(file);
  //       }
  //     } else {
  //       alert('You could only upload images.');
  //     }
  //   };

  //   input.click();
  // }

  loader(status: string){
    this.appService.setloaderVisibility(status);
  }
}
