import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Cookie } from 'ng2-cookies/ng2-cookies';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = Cookie.get('token'); // you probably want to store it in localStorage or something
    if (!token) {
      return next.handle(req);
    }
    req = req.clone({
      setHeaders: {
        Authorization: `Token ${token}`
      }
    });
    return next.handle(req);
  }
}
