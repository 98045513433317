import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from './user.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { AddUserModalComponent } from './add-user-modal/add-user-modal.component';
import { ConfirmDeleteModalComponent } from '../../shared/modal/confirm-delete-modal/confirm-delete-modal.component';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AppService } from "../../../app.service";
declare var $: any;

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  userList: any;
  totalRecords: any;
  toDeleteUserId: any;
  pageNumber: any = 1;
  pageSize: any = 30;
  tempPageObject: any = {};
  searchUserObject: any = {};
  superpowers: any = [];
  customInput: Subject<string> = new Subject();
  pageSizeList: string[] = ["30", "50", "70"];
  selectedPageSize = "30";
  config: any;
  userObject: any = {};
  selectedFilterRole: any = '';
  imageUrl = '';
  isUrl: boolean = false;
  schoolList:any = [];
  schoolId:any = '';
  userProgress:any = [];
   quizProgress:any = [];
  empty_records:string= "";
  currentUserId:string= "";
  isUserActivity:boolean = false;
  userDetails:any= {}
  totalPoints:number;
  @ViewChild(AddUserModalComponent, { static: false })
  addUserModalComponent: AddUserModalComponent;
  @ViewChild(ConfirmDeleteModalComponent, { static: false })
  confirmDeleteModalComponent: ConfirmDeleteModalComponent;

  constructor(
    private service: UserService, 
    private toastr: ToastrManager,
    private appService: AppService
    ) {
    this.customInput.pipe(debounceTime(1000), distinctUntilChanged()).subscribe(value => {
      this.searchUserObject.Name = value;
      this.loadUsers(this.searchUserObject);
    });
  }

  ngOnInit(): void {
    this.config = {
      itemsPerPage: this.selectedPageSize,
      currentPage: this.pageNumber,
      totalItems: 0
    };
    this.getFilter();
    this.loadAllScholl();
    this.loadUsers(this.searchUserObject);
  }

  loadAllScholl() {
      this.service.getAllSchool().subscribe((res: any) => {
        this.schoolList = res.result;
      });
  }

  loadUsers(event) {
    this.loader('show');
    let data = {};
    data = {
      'pageSize': this.pageSize, 'pageIndex': this.pageNumber,'school_id':this.schoolId, 'is_staff': this.selectedFilterRole,
      'searchUser': this.searchUserObject ? (this.searchUserObject.Name ? this.searchUserObject.Name : '') : ''
    };

    this.service.getAllUser(data).subscribe((res: any) => {
      this.userList = res.results;
      this.userList.forEach(element => {
        element.productKey = '';
        if (element.user.length > 0) {
          var userKey = element.user[0];
          element.productKey = userKey.key;
        }
        if (element.is_staff === true) {
          element.role = 'Admin';
        } else {
          element.role = 'User';
        }
      });
      this.totalRecords = res.count;
      this.config = {
        itemsPerPage: this.selectedPageSize,
        currentPage: this.pageNumber,
        totalItems: this.totalRecords
      };
      this.setFilter();
      this.loader('hide');
    }, err => {
      this.loader('hide');
    });
  }

  loader(status: string){
    this.appService.setloaderVisibility(status);
  }

  searchUser(event) {
    this.customInput.next(event);
  }

  pageChanged(event) {
    this.pageNumber = event;
    this.loadUsers(this.searchUserObject);
    this.config.currentPage = event;
  }

  onPageSizeChange(event) {
    this.pageSize = event;
    this.pageNumber = 1;
    this.loadUsers(this.searchUserObject);
  }

  setSelected(data) {
    this.schoolId = '';
    if (data === '') {
      this.selectedFilterRole = data;
    }else if (data === 'True'){
      this.selectedFilterRole = data;
    }else if (data === 'False') {
      this.selectedFilterRole = data;
    }else{
      this.schoolId = data.pk;
    }
    this.loadUsers(this.searchUserObject);
  }

  setFilter() {
    this.searchUserObject.pageNumber = this.pageNumber;
    this.searchUserObject.pageSize = this.pageSize;
    this.searchUserObject.selectedFilterRole = this.selectedFilterRole;
    this.searchUserObject.module = 'user';
    localStorage.setItem('searchObject', JSON.stringify(this.searchUserObject));
  }

  getFilter() {
    var searchObject = JSON.parse(localStorage.getItem('searchObject'));
    if (searchObject !== null) {
      if (searchObject.module === 'user') {
        this.searchUserObject = searchObject;
        this.pageNumber = this.searchUserObject.pageNumber;
        this.pageSize = this.searchUserObject.pageSize;
        this.selectedPageSize = this.searchUserObject.pageSize;
        this.selectedFilterRole = this.searchUserObject.selectedFilterRole;
      }
    }
  }
  addUser() {
    this.addUserModalComponent.addUser();
  }

  editUser(data) {
    this.addUserModalComponent.editUser(data);
  }

  viewUser(data) {
    this.imageUrl = '';
    this.userObject = data;
    if (this.userObject.image_url !== '' && this.userObject.image_url !== undefined && this.userObject.image_url !== null) {
      this.isUrl = true;
    }
    this.imageUrl = this.userObject.image_url;
  }

  confirmDelete(deleteUser) {
    this.toDeleteUserId = deleteUser.pk;
  }

  deleteUser() {
    this.service.deleteUser(this.toDeleteUserId).subscribe(
      (res: any) => {
        this.toastr.successToastr('Deleted successfully.');
        $('#deleteModaluser').modal('hide');
        this.loadUsers(this.tempPageObject);
      },
      (error) => {
        this.loader('hide');
        this.toastr.errorToastr('User can not be deleted because of associated activities');
      }
    );
  }

  saveUser() {
    this.addUserModalComponent.saveUser();
  }

  viewProgress(data) {
    this.loader('show');
    this.userProgress = [];
    this.quizProgress = [];
    this.empty_records = ""
    this.userDetails = data;
    if(data.user.length > 0){
    this.service.userProgress(data.pk).subscribe((res:any) => {
    if (res.topics.length > 0) {
      res.topics.forEach((element: any) => {
        if (element.name.includes(' Quiz')) {
         this.quizProgress.push(element)
        }else {
           this.userProgress.push(element)
           console.log('userProgress218',this.userProgress);
           }
        this.totalPoints = res.total_points;
      })
      } else {
        this.empty_records = "No Records Found"
      }
      if (res.superpowers.length > 0) {
        this.superpowers =  res.superpowers;
        console.log('superpowers',this.superpowers);
        }
      this.isUserActivity = true;
      this.loader('hide');
    },(error) => {
      this.loader('hide');
      this.toastr.errorToastr('user progress failed.');
    })
  }else {
    this.currentUserId = localStorage.getItem('userId');
    this.service.userProgress(this.currentUserId).subscribe((res:any) => {
      console.log('userProgress234',this.userProgress);
      if (res.topics.length > 0) {
        res.topics.forEach((element: any,index: any) => {
          // if (index === 1 && index === 5) {
          //   this.quizProgress.push(element)
          // }else {
            this.userProgress.push(element)
         // }
        })
        } else {
          this.empty_records = "No Records Found"
        }
      	this.isUserActivity = true;
        this.loader('hide');
      },(error) => {
        this.loader('hide');
        this.toastr.errorToastr('user progress failed.');
      })
  }
}
}
