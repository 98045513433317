import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class LeaderBoardService {
  constructor(private http: HttpClient) { }

  

   getAllLeaderBoard(data) {
    // return this.http.get(environment.BASE_URL + 'user/leaderboard/list?search=${data.search}');
    return this.http.get(environment.BASE_URL + `user/leaderboard/list?school_id=${data.schoolId}`);

     
  }

  getSchools() {
    return this.http.get(environment.BASE_URL + 'user/schools').pipe(map(res => res));
  }

  getAllDistictSchool() {
    // return this.http.get(environment.BASE_URL + 'user/leaderboard/list?search=${data.search}');
    return this.http.get(environment.BASE_URL + `user/leaderboard/list`);

     
  }
  

}