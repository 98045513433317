import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router} from '@angular/router';
import {ActivatedRoute} from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ArticleService } from '../article.service';
import { TopicService } from '../../topic/topic.service';
import { environment } from '../../../../../environments/environment';
import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill'
import { AppService } from "../../../../app.service";

declare var $: any;

@Component({
  selector: 'app-add-article-modal',
  templateUrl: './add-article-modal.component.html',
  styleUrls: ['./add-article-modal.component.css']
})
export class AddArticleModalComponent implements OnInit {
  modal_title: any;
  articleObject: any = {};
  topicList: any = [];
  totalRecords: any;
  pageNumber: any = 1;
  duration: any;
  pageSize: any = 100;
  tempPageObject: any = {};
  selectedTopic: any = {};
  file:any ={};
  articleContent: string;
  isImageUpload:boolean = false;
  imageUrl: string | ArrayBuffer =  "";
  fileName: string = "No file selected";
  fileRemove: boolean =false;
  name = 'Angular 6';
  quillEditorRef;
  maxUploadFileSize = 1000000;
  @Output() callGetAllArticleDataEvent = new EventEmitter<any>();
  constructor(
    private router: Router,
    private route: ActivatedRoute, 
    private service: ArticleService,
    private topicService: TopicService, 
    private toastr: ToastrManager,
    private appService: AppService) {
      this.route.params.subscribe(params => {
        console.log(params)
        if(params.id){
         this.getAllTopic().then( a => this.getArticleById(params.id));    
         this.duration = params.duration;      
        }
      });
     }

  ngOnInit(): void {
    this.getAllTopic();
    this.articleObject.is_published =true;
    this.modal_title = 'Add Learn';
    let dur : any = document.getElementById("duration");
    dur.value = this.duration;
  }

  onFileChange(file: File) {
    if (file) {
      this.fileName = file.name;
      this.file[0] = file;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = event => {
        this.imageUrl = reader.result;
        this.isImageUpload = true;
        this.fileRemove =false;
      };
    }
  }
  removeImage(){
    this.file = {};
    this.isImageUpload = false;
    this.imageUrl = '';
    this.fileRemove =true;
  }

  getArticleById(id){
    this.loader('show');
    this.service.getArticleByID(id).subscribe((res: any) => {
      if(res){
        this.articleObject = res;
        if(this.articleObject.image !=='' && this.articleObject.image !== null){
          this.isImageUpload = true;
          this.imageUrl = environment.BASE_URL.replace('/api/','') + this.articleObject.image;
        }        
        this.articleContent = this.articleObject.content
        this.articleObject.duration = new Date(this.articleObject.duration_second * 1000 * 60).toISOString().substr(11, 8)
        delete  this.articleObject.duration_second;
        if(this.articleContent){
          this.quillEditorRef.clipboard.dangerouslyPasteHTML(this.articleContent);
        }
        this.selectedTopic = this.topicList.find(i=> i.pk === this.articleObject.category_id);
        if(this.articleObject){
          this.modal_title = 'Edit Learn';
        }      
      }
      this.loader('hide');
    }, err => {
      this.loader('hide');
    });   
  }
  getArticleFile(id){
    this.service.getArticleFile(id).subscribe((res:any) =>{
    });
  }
  addArticle() {
    this.articleObject = {};
    this.articleObject.is_published = true;
    this.articleContent = "";
    this.modal_title = 'Add Learn';
  }
  editArticle(data) {
    this.articleObject = data;
    this.articleContent = this.articleObject.content
    this.modal_title = 'Update Article';
  }

  contentArticle(data) {
    
    this.articleContent = data;
  }

  imageArticleList(imgList) {
    
    this.file =imgList;
  }

  getAllTopic() {
    return new Promise((resolve, reject) => {
    const data = { 'pageSize': this.pageSize, 'pageIndex': this.pageNumber, 'searchName': '', 'categoryType': 'Learn' };
    this.topicService.getAllTopic(data).subscribe((res: any) => {
      this.topicList = res.results;
      resolve(this.topicList);
      this.topicList.forEach(element => {
        if (element.is_published === true) {
          element.status = 'Yes';
        } else {
          element.status = 'No';
        }
      });
      this.totalRecords = res.count;
    }, err => {

    });
  });
  }
  saveArticle() {
    try {
      this.articleObject.article_type = 'image';
      this.articleObject.question_type = 'question';
      this.articleObject.user_question = 'False';
      this.articleObject.answer_value = '';
      this.articleObject.explanation = '';
      this.articleObject.explanation_file_id = '';
      this.articleObject.category_id = this.selectedTopic.pk;
      this.articleObject.content = this.articleContent;
      this.articleObject.url = '';
      this.articleObject.difficulty_type ="easy";
      if (this.articleObject.pk) {
        this.articleObject.article_id = this.articleObject.pk;
        this.service.updateArticle(this.articleObject).subscribe(data => {
          let fileData = {'file' :this.file[0], 'data' : data, 'fileRemove': this.fileRemove }
          if(fileData.file || this.fileRemove){
           this.service.addArticleFile(fileData).subscribe(res =>{
              
             }); }      
          this.router.navigate(['article']);
          this.toastr.successToastr('Article updated successfully');
          this.callGetAllArticleDataEvent.emit('');
        });
      } else {
        this.service.createArticle(this.articleObject).subscribe(data => {
          this.articleObject = data;
          let fileData = {'file' :this.file[0], 'data' : data, 'fileRemove': this.fileRemove }
          if(fileData.file){
           this.service.addArticleFile(fileData).subscribe(res =>{
              
            }); }   
            this.router.navigate(['article']);
          this.toastr.successToastr('Article added successfully');
          this.callGetAllArticleDataEvent.emit('');
        });
      }
    } catch (ex) {
      this.toastr.errorToastr('Please enter valid data');
    }
  }

  changedEditor(event: EditorChangeContent | EditorChangeSelection) {
    this.articleContent = document.querySelector(".ql-editor").innerHTML;   
  }
  getEditorInstance(editorInstance: any) {
    this.quillEditorRef = editorInstance;
    const toolbar = editorInstance.getModule('toolbar');
    toolbar.addHandler('image', this.imageHandler);
  }
  
  imageHandler = (image, callback) => {
    const input = <HTMLInputElement> document.getElementById('fileInputField');
    document.getElementById('fileInputField').onchange = () => {
      let file: File;
      file = input.files[0];
      if (/^image\//.test(file.type)) {
        if (file.size > this.maxUploadFileSize) {
          alert('Image needs to be less than 1MB');
        } else {
          const reader  = new FileReader();
          reader.onload = () =>  {
            const range = this.quillEditorRef.getSelection();
            const img = '<img src="' + reader.result + '" width="103%" height="auto"/>';
            this.quillEditorRef.clipboard.dangerouslyPasteHTML(range.index, img);
          };
          reader.readAsDataURL(file);
        }
      } else {
          alert('You could only upload images.');
      }
    };
    input.click();
  }

  loader(status: string){
    this.appService.setloaderVisibility(status);
  }
  setDuration(duration: any){
    this.duration = duration;
  }
}
