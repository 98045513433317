import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpEventType,HttpRequest,HttpResponse } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import { NgxSpinnerService } from "ngx-spinner";
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class VideoService {
  constructor(private http: HttpClient,private spinner: NgxSpinnerService) { }


  createVideo(data) {
    //let file: File = data.file;
    let formData: FormData = new FormData();
    // formData.append('file', file, file.name);
    formData.append('title', data.title);
    formData.append('description', data.description);
    formData.append('content', data.content);
    formData.append('is_published', data.is_published);
    formData.append('article_type', data.article_type);
    formData.append('question_type', data.question_type);
    formData.append('user_question', data.title);
    formData.append('answer_value', data.answer_value);
    formData.append('explanation', data.explanation);
    formData.append('explanation_file_id', data.explanation_file_id);
    formData.append('difficulty_type', data.difficulty_type);
    formData.append('category_id', data.category_id);
    formData.append('url', data.url);
    // var obj: any = {
    //   "title": data.title,
    //   "description": data.description,
    //   "content": data.content,
    //   "is_published":data.is_published,
    //   "article_type":data.article_type,
    //   "question_type":data.question_type,
    //   "user_question":data.title,
    //   "answer_value":data.answer_value,
    //   "explanation":data.explanation,
    //   "explanation_file_id":data.explanation_file_id,
    //   "difficulty_type":data.difficulty_type,
    //   "category_id":data.category_id,
    //   "url":data.url
    // }
    // formData.append('uploadFileView', obj);
    let headers = new HttpHeaders();
    

    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    let options = { headers: headers };
    return this.http.post(environment.BASE_URL + 'topic/article', data).pipe(map(res => res));
  }
  getAllVideo(data) {
   return this.http.get(environment.BASE_URL + 'topic/article/list?page=' + data.pageIndex + '&page_size=' + data.pageSize + '&search_article=' + data.searchArticle +'&category_id=' + data.category_id + '&article_type=video&is_published=' + data.is_published).pipe(map(res => res));
  }
  updateVideo(data) {
    return this.http.put(environment.BASE_URL + 'topic/article', data).pipe(map(res => res));
  }
  deleteVideo(data) {
    return this.http.delete(environment.BASE_URL + 'topic/article?article_id=' + data.article_id).pipe(map(res => res));
  }
  getVideoByID(id) {
    return this.http.get(environment.BASE_URL + 'topic/article?id=' + id + '').pipe(map(res => res));
  }
  addVideoFile(fileData) {
    let file: File = fileData.file;
    let formData: FormData = new FormData();
    if(file){
      formData.append('file', file, file.name); 
    }
    formData.append('article_id', fileData.data.article_id);
    formData.append('url', fileData.data.url);
    formData.append('fileRemove', fileData.fileRemove);
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    let options = { headers: headers };
     const req = new HttpRequest('POST', environment.BASE_URL + 'topic/article/video', file, {
      reportProgress: true,
    });

    this.http.request(req).subscribe(event => {
      // Via this API, you get access to the raw event stream.
      // Look for upload progress events.
      if (event.type === HttpEventType.UploadProgress) {
        // This is an upload progress event. Compute and show the % done:
        const percentDone = Math.round(100 * event.loaded / event.total);
       // console.log(`File is ${percentDone}% uploaded.`);
      } else if (event instanceof HttpResponse) {
       // console.log('File is completely uploaded!');
      }
    });
    
   

    return this.http.post(environment.BASE_URL + 'topic/article/video', formData, options).pipe(map(res => res));
  }
  

  getVideoFile(id) {
     return this.http.get(environment.BASE_URL + 'topic/article/video?id=' + id).pipe(map(res => res));
    }
}