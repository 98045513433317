import { Component, OnInit, ViewChild } from '@angular/core';
import { AddPledgeModalComponent } from '../pledges/add-pledge-modal/add-pledge-modal.component';
import { PledgeService } from './pledge.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { TopicService } from '../topic/topic.service';
import {Router} from '@angular/router';
import { AppService } from "../../../app.service";
import { sortArrByDate } from "../../shared/utility-functions";

declare var $: any;
@Component({
  selector: 'app-pledge',
  templateUrl: './pledge.component.html',
  styleUrls: ['./pledge.component.css']
})
export class PledgeComponent implements OnInit {
  articleList: any;
  totalRecords: any;
  toDeleteArticleId: any;
  pageNumber: any = 1;
  pageSize: any = 30;
  tempPageObject: any = {};
  searchArticleObject: any = {};
  customInput: Subject<string> = new Subject();
  pageSizeList: string[] = ["30", "50","70"];
  selectedPageSize = "30";
  config: any;
  articleId =1;
  topicList: any = [];
  publishedStatus: any = '';
  filterCategoryId: any ='';
  moduleList:any;
  moduleIdList:any = [];
  testimonials:any = [];
  selectedTestimonial: any = {};
  userActivities: any = [];
  pledgeObject: any = {};

  @ViewChild(AddPledgeModalComponent, { static: false })
  addArticleModalComponent: AddPledgeModalComponent;
  constructor(
    private service: PledgeService, 
    private topicService: TopicService,
    private router: Router, 
    private toastr: ToastrManager,
    private appService: AppService
    ) { 
    this.customInput.pipe(debounceTime(1000), distinctUntilChanged()).subscribe(value => {
      this.searchArticleObject.Name = value;
      this.loadArticles(this.searchArticleObject);
    });
  }

  ngOnInit(): void {
    this.config = {
      itemsPerPage: this.selectedPageSize,
      currentPage: this.pageNumber,
      totalItems: 0
    };
    this.getFilter();
    this.loadArticles(this.searchArticleObject);
    this.getAllModules();
    this.getTestimonials();
  }

  getTestimonials(search?: any){
    search = search ? search : '';
    this.service.getTestimonial(search).subscribe((res: any) => {
      this.testimonials = [];
      // let testimonials = [];
      res.results.forEach((element: any) => {
        let got = this.testimonials.find(testi => testi.pk == element.pk);
        if(!got){
          element.category_name = this.getModuleName(element?.category_id);
          this.testimonials.push(element);
        }
      });
      this.testimonials = sortArrByDate(this.testimonials, 'updated_at');
      // testimonials?.forEach((testimonial: any) => {
      //   let articleActivity = testimonial.articleActivity;
      //   // let testimonial: any = new Object(element);
      //   delete testimonial.articleActivity;
      //   testimonial.category_name = this.getModuleName(testimonial?.category_id);
      //   if(articleActivity?.length){
      //     articleActivity?.forEach((activity: any) => {
      //       let newTestimonial = { ...testimonial };
      //       newTestimonial.pledge_image = activity.pledge_image;
      //       newTestimonial.pledge_video = activity.pledge_video;
      //       newTestimonial.user_id = activity.user_id;
      //       newTestimonial.username = activity.username;
      //       this.testimonials.push(newTestimonial);
      //     });  
      //   } else {
      //     this.testimonials.push(testimonial);
      //   }
      // });
    })
  }

  getAllModules() {
    let data = {};
    data = { 'pageSize': 1000, 'pageIndex': 1, 'categoryType': '', 'searchName': '' };
    this.topicService.getAllTopic(data).subscribe((res: any) => {
      this.moduleList = res.results;  
      this.moduleList.forEach(element => {
        if(element.is_published === true){
          element.status = 'Published';
        } else{
          element.status = 'Draft';
        }
      });         
    }, err => {
      
    });
  }

  loadArticles(event) {
    this.loader('show');        
    let data = {};
    data = { 'pageSize': this.pageSize, 'pageIndex': this.pageNumber, 'category_id': this.filterCategoryId, 'is_published' : this.publishedStatus,
    'searchArticle':  this.searchArticleObject ? ( this.searchArticleObject.Name ? this.searchArticleObject.Name : '' ): '' };
    
    this.service.getAllArticle(data).subscribe((res: any) => {
      this.loader('hide');
      this.articleList = res.results;
      this.articleList.forEach(element => {       
        if(element.is_published === true){
          element.status = 'Published';
        } else{
          element.status = 'Draft';
        }
      });
      this.totalRecords = res.count;
      this.config = {
        itemsPerPage: this.selectedPageSize,
        currentPage: this.pageNumber,
        totalItems: this.totalRecords
      };
      this.setFilter();
    }, err => {
      this.loader('hide');
    });
  }
  getAllTopic() {
    
    return new Promise((resolve, reject) => {
    const data = { 'pageSize': 100, 'pageIndex': 1, 'searchName': '' };
    this.topicService.getAllTopic(data).subscribe((res: any) => {
      this.topicList = res.results;     
    }, err => {
    });
  });
  }
  searchArticle(event) {
    this.customInput.next(event);
  }
  setSelected(data){
    this.filterCategoryId = "";
    if (data === '') {
      this.publishedStatus = "";
    }else if (data === 'True'){
      this.publishedStatus = data;
    }else if (data === 'False') {
      this.publishedStatus = data;
    }else{
      this.filterCategoryId = data.pk.toString();
    }
    this.loadArticles(this.searchArticleObject);
  }

  setFilter(){
    this.searchArticleObject.pageNumber = this.pageNumber;
    this.searchArticleObject.pageSize = this.pageSize;
    this.searchArticleObject.publishedStatus = this.publishedStatus;
    this.searchArticleObject.module = 'article';
    localStorage.setItem('searchObject', JSON.stringify(this.searchArticleObject));
  }

  getFilter(){  
    var searchObject = JSON.parse(localStorage.getItem('searchObject'));
    if( searchObject !== null){
      if(searchObject.module === 'article'){
        this.searchArticleObject = searchObject;
        this.pageNumber =this.searchArticleObject.pageNumber ;
        this.pageSize = this.searchArticleObject.pageSize ;
        this.selectedPageSize = this.searchArticleObject.pageSize ;
        this.publishedStatus= this.searchArticleObject.publishedStatus ;
      }
    }  
  }

  addArticle() {
    const data = {}
    this.router.navigate(['add-article'], { state: { data } });
  }
  editArticle(data) {
    this.router.navigate(['add-article'], { state: { data } });
  }
  confirmDelete(deleteArticle) {
    this.toDeleteArticleId = deleteArticle.pk;
  }
  deleteArticle() {
    const data = { 'article_id': this.toDeleteArticleId };
    this.service.deleteArticle(data).subscribe((res: any) => {
      if (res.success) {
        this.toastr.successToastr('Deleted successfully.');
        $('#deleteModal').modal('hide');
        this.loadArticles(this.tempPageObject);
      }
    },
      (error) => {
        this.toastr.errorToastr('  Deletion Failed. Pledge can not be deleted because of associated activities.');
      }
    );
  }

  pageChanged(event){
    this.pageNumber = event;
    this.loadArticles(this.tempPageObject);  
  }

  onPageSizeChange(event){
    this.pageSize = event;
    this.pageNumber = 1;
    this.loadArticles(this.tempPageObject);
  }

  getModuleName = (id: any) => {
    let module = this.moduleList?.find((mod: any) => mod.pk == id);
    return module?.name;
  }

  setSelectedTestimonial(data: any){
    this.selectedTestimonial = data;
  }

  loader(status: string){
    this.appService.setloaderVisibility(status);
  }

  searchTestimonial(data?: any){
    this.getTestimonials(data);
  }

  setUserActivity(activity: any){
    this.userActivities = activity;
    this.userActivities?.forEach((element: any) => {
      element.activityTime = this.formatDate(element?.started_at);
    });
  }
  formatDate(date: any){
    if(!date) return '';
    let dt = new Date(date);
    return `${dt.getDate()}/${dt.getMonth()+1}/${dt.getFullYear()} ${dt.getHours()}:${dt.getMinutes()}:${dt.getSeconds()}`
  }

  viewPledge(data:any) {
    this.pledgeObject = data;
  }
}
