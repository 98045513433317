import { Component, OnInit,Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.css']
})
export class UploadFileComponent implements OnInit {

  files: File[] = [];
  @Output() callGetImageDataEvent = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
    this.toDataURL('http://localhost:8000/images/images/covid_U957D6b.jpg', function (dataUrl) {
      
      //console.log(dataUrl)
      var imgURI = 'data:image/jpeg;base64,' + dataUrl;
     var blob= this.convertToBlob(imgURI)
     var fdata = new FormData();
     fdata.append('file', blob, 'a.jpg');
     this.files.push(fdata);
          })
  }
  toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
            callback(reader.result);
        }
        reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
}
 convertToBlob(imgData){
  var byteString = atob(imgData.split(',')[1]);
  var mimeString = imgData.split(',')[0].split(':')[1].split(';')[0];
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++)
  {
  ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { 'type': mimeString });
  }

	onSelect(event) {
    
	//	console.log(event);
    this.files.push(...event.addedFiles);
    this.callGetImageDataEvent.emit(this.files);
	}

	onRemove(event) {
    
		//console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
    this.callGetImageDataEvent.emit(this.files);
	}

}
